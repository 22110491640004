//Importamos los ladrillos de edición

import Tabs from './Tabs';
import Repeater from './Repeater';
import RepeaterFlexContent from './RepeaterFlexContent';
import Image from './Image';
import Text from './Text';
import TextArea from './TextArea';
import TextAreaGoogleMap from './TextAreaGoogleMap';
import Number from './Number';
import Video from './Video';
import Audio from './Audio';
import Switch from './Switch';
import SwitchTest from './SwitchTest';
import TextEditor from './TextEditor';
import ContainerStyle from './ContainerStyle';
import ImageBg from './ImageBg';
import ColorBg from './ColorBg';
import ColorLayerOpacity from './ColorLayerOpacity';
import CustomColor from './CustomColor';
import PaddingTop from './PaddingTop';
import PaddingBottom from './PaddingBottom';
import ThemeSelector from './ThemeSelector';
import Coordinates from './Coordinates';
import EffectSelector from './EffectSelector';
import PillSelector from './PillSelector';
import Select from './Select';
import Accordions from './Accordions';
import TagSelect from './TagSelect';
import ColorAuto from './ColorAuto';
import CsvDownload from './CsvDownload';
import File from './File';
import DataSheet from './DataSheet';
import DataSheetPie from './DataSheetPie';
import YAxisConfig from './YAxisConfig';
import CodeEditor from './CodeEditor';
import UniqueSlug from './UniqueSlug';
import SuperAdmin from './SuperAdmin';
import GenerateQuestion from './GenerateQuestion';
import DateTimeSelector from './DateTimeSelector';
import PixelProperty from './PixelProperty';
//Creamos un array con las asociaciones entre texto y componente
export const EDITION_BRICKS = {
	Tabs,
	Repeater,
	RepeaterFlexContent,
	Image,
	Text,
	TextArea,
	TextAreaGoogleMap,
	Number,
	Switch,
	SwitchTest,
	TextEditor,
	ContainerStyle,
	ImageBg,
	ColorBg,
	ColorLayerOpacity,
	CustomColor,
	PaddingTop,
	PaddingBottom,
	ThemeSelector,
	EffectSelector,
	PillSelector,
	Coordinates,
	Select,
	Video,
	Audio,
	Accordions,
	TagSelect,
	ColorAuto,
	CsvDownload,
	File,
	DataSheet,
	DataSheetPie,
	YAxisConfig,
	CodeEditor,
	UniqueSlug,
	SuperAdmin,
	GenerateQuestion,
	DateTimeSelector,
	PixelProperty,
};

export const getDefaultValue = (editionBrickStruct) => {
	const EditionBrick = EDITION_BRICKS[editionBrickStruct.type];
	if (EditionBrick && EditionBrick.getDefaultValue) {
		return EditionBrick.getDefaultValue(editionBrickStruct);
	}

	return '';
};
