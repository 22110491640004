import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
					},
					{
						type: 'Switch',
						fieldName: 'showNumbers',
						title: t`Mostrar números en los puntos calientes`,
					},
					{
						type: 'Repeater',
						min: 0,
						fieldName: 'hotSpots',
						blockTitle: t`Punto caliente`,
						content: [
							{
								type: 'Coordinates',
								fieldName: 'coordinates',
								title: t`Coordenadas`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'CustomColor',
						name: t`Color capa de velado sobre imagen`,
						fieldName: ['colorLayer'],
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
