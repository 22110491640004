import gql from 'graphql-tag';

export const SIGNUP_MUTATION = gql`
	mutation Signup(
		$email: String!
		$password: String!
		$name: String
		$customFields: ScalarGeneric
	) {
		signup(email: $email, password: $password, name: $name, customFields: $customFields)
	}
`;

export const LOGIN_MUTATION = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password)
	}
`;

export const SET_PASSWORD_BY_TOKEN_MUTATION = gql`
	mutation SetPasswordByToken($newPassword: String!, $tokenId: String!, $name: String) {
		setPasswordByToken(newPassword: $newPassword, tokenId: $tokenId, name: $name)
	}
`;

export const RESET_PASSWORD_MUTATION = gql`
	mutation ResetPassword($email: String!, $admin: Boolean) {
		resetPassword(email: $email, admin: $admin)
	}
`;

export const GET_USER_DATA = gql`
	query DataUser {
		dataUser {
			id
			name
			email
			admin
			superAdmin
			data
			adminAsUser
			adminAsGroup
			authDomain
			imageObj {
				imageSrc
				centerPosition {
					x
					y
				}
			}
			config
			lang
		}
	}
`;

export const GET_TOKEN = gql`
	query Token($tokenId: String!, $type: String) {
		token(tokenId: $tokenId, type: $type) {
			error
			token {
				id
				user {
					id
					name
					email
				}
				data
			}
		}
	}
`;

export const UPDATE_USER_NAME = gql`
	mutation userUpdateData($name: String!) {
		userUpdateData(input: {name: $name}) {
			error
		}
	}
`;

export const SET_PUSH_TOKEN = gql`
	mutation userSetPushToken($pushToken: String!, $pushProvider: String) {
		userSetPushToken(pushToken: $pushToken, pushProvider: $pushProvider)
	}
`;

export const REMOVE_PUSH_TOKEN = gql`
	mutation userRemovePushToken($pushToken: String!) {
		userRemovePushToken(pushToken: $pushToken)
	}
`;

export const CHECK_PROXY_EMAIL = gql`
	query CheckProxyEmail($email: String!) {
		checkProxyEmail(email: $email) {
			email
			available
			entities {
				id
				name
				slug
			}
		}
	}
`;
