import store, {client, adminAsUserToken} from 'businessLogic/store/store.js';
import * as CONST from './constants.js';
import {signUpMutation, setPushToken, removePushToken} from './graphql/mutations';
import {GET_USER_DATA} from './graphql/query';
import statLogger from 'businessLogic/services/StatLogger';
import logger from 'businessLogic/services/Logger';
import googleAnalyticsManager from 'businessLogic/services/GoogleAnalyticsManager';
import adminPerms from '@createandshare/adminperms';
import get from 'lodash/get';
import entityManager from 'businessLogic/services/EntityManager';

export async function signUp(email, password, name, customFields) {
	const token = await signUpMutation(email, password, name, customFields);
	return token;
}

export async function loginUser({
	email,
	password,
	passwordType = 'plain',
	rememberMe = false,
	admin = false,
	urlRedirect,
	authDomain,
}) {
	const response = await fetch('/login', {
		method: 'post',
		body: JSON.stringify({
			username: email,
			password,
			passwordType,
			rememberMe,
			admin,
			authDomain,
		}),
		credentials: 'same-origin',
		mode: 'same-origin',
	});

	const responseJson = await response.json();

	if (responseJson.error !== 0) {
		return {
			error: true,
			errorMessage: responseJson.message,
		};
	} else {
		if (localStorage && urlRedirect) {
			if (urlRedirect.startsWith('/')) {
				localStorage.setItem('beforeRoute', urlRedirect);
			} else {
				const url = new URL(urlRedirect);
				localStorage.setItem('beforeRoute', url.pathname);
			}
		}
		initialDataSession(responseJson.user);

		//Lanzamos el evento de login en plataforma
		statLogger.loggedIn();

		return {
			error: false,
		};
	}
}

export async function autoLogin() {
	const response = await fetch('/autologin', {
		method: 'get',
	});

	const responseJson = await response.json();

	if (responseJson.error !== 0) {
		return {
			error: true,
			errorMessage: responseJson.message,
		};
	} else {
		initialDataSession(responseJson.user);

		//Lanzamos el evento de login en plataforma
		statLogger.loggedIn();

		return {
			error: false,
		};
	}
}

export async function checkLoggedUser() {
	if (process.env.REACT_APP_EVENTS_API === 'scorm') return;

	// Si desde el servidor nos indican que no hay usuario logado, no hacemos la llamada al servidor
	if (window.notLoggedFromServer) {
		window.notLoggedFromServer = undefined;
		initialDataSession(null);
		return {
			error: false,
			user: null,
		};
	}

	const loggedUserUrl = adminAsUserToken ? `/loggeduser?aau=${adminAsUserToken}` : '/loggeduser';
	const response = await fetch(loggedUserUrl, {
		method: 'get',
		credentials: 'same-origin',
		mode: 'same-origin',
	});

	const responseJson = await response.json();

	if (responseJson.error !== 0) {
		return {
			error: true,
			errorMessage: responseJson.message,
		};
	} else {
		initialDataSession(responseJson.user);

		if (responseJson.user) {
			//Lanzamos el evento de login en plataforma
			statLogger.loggedIn();
		}

		return {
			error: false,
			user: responseJson.user,
		};
	}
}

export async function logoutUser() {
	// const currentUser = getDataUser(store.getState());

	// Si estamos en la nativeApp, desasociamos el pushToken al usuario
	if (localStorage) {
		const pushToken = localStorage.getItem('nativeApp_pushToken');
		if (pushToken) {
			await removePushToken(pushToken);
		}
	}

	//Lanzamos el evento de logout en plataforma para los usuarios normales
	const response = await fetch('/logout', {
		method: 'get',
	});
	const jsonResponse = await response.json();
	deleteDataSession();

	// Borramos toda la caché de Apollo
	client.clearStore();

	// Es posible que la respuesta nos indique que debemos hacer una redirección para cerrar sesión en un agente externo
	if (jsonResponse.redirect) {
		window.location.href = jsonResponse.redirect;
		return;
	}
}

export function initialDataSession(dataUser) {
	store.dispatch({type: CONST.SET_DATA_SESSION, dataPayload: dataUser});
	client.writeQuery({query: GET_USER_DATA, data: {dataUser}});

	// Si no es preview y el usuario tiene un idioma asignado, cargamos ese idioma
	const userLang = get(dataUser, 'lang');
	const preview = window.location.search.includes('preview=true');
	const aau = window.location.search.includes('aau=');

	if (userLang && (!preview || aau)) {
		entityManager.configureLang(userLang);
	}

	// Set Admin Perms
	adminPerms.setUser(dataUser);

	//Set Google Analytics UserId
	googleAnalyticsManager.setUserId(dataUser);
	logger.setUser(dataUser);

	// Si estamos en la nativeApp, asociamos el pushToken al usuario
	if (localStorage) {
		const pushToken = localStorage.getItem('nativeApp_pushToken');
		const pushProvider = localStorage.getItem('nativeApp_pushProvider');
		if (pushToken) {
			setPushToken(pushToken, pushProvider);
		}
	}
}

export function deleteDataSession() {
	store.dispatch({type: CONST.DELETE_DATA_SESSION});
	client.writeQuery({query: GET_USER_DATA, data: {dataUser: null}});
	logger.clearUser();
	adminPerms.clearUser();
}
