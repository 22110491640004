import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Icon from 'ui/shared/Icon';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';
import './styles.scss';

class ExternalDownloadButton extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Descargar recurso externo`;
	static brickIcon = 'pdf';
	static brickImage = brickImage;
	static brickDefaultData = () => ({
		downloadUrl: '',
		button: _('Descargar'),
	});
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const downloadUrl = data.downloadUrl;
		const buttonText = data.button;
		const openInsteadOfDownload = data.openInsteadOfDownload || false;
		const openInCurrentTab = data.openInCurrentTab || false;

		const {interactionColor, lightFontColor, darkFontColor} = entityManager.getCustomStyles();
		const bgColor = styles.bgColor;

		const bgColorObject = bgColor ? getColorObject(bgColor) : getColorObject(interactionColor);

		const textColorObject = bgColorObject.tinycolor.isDark()
			? getColorObject(lightFontColor)
			: getColorObject(darkFontColor);

		const classes = classNames({
			'brick': true,
			'external-download-button-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<a
						className="external-download-button-brick__button"
						href={downloadUrl}
						download={!openInsteadOfDownload}
						target={openInCurrentTab ? '_self' : '_blank'}
						rel="noopener noreferrer"
						onClick={() => {
							this.props.brickEvent('click');
						}}
						style={{
							backgroundColor: bgColorObject.hex,
							color: textColorObject.hex,
						}}
					>
						{/* <div className="external-download-button-brick__pulse" /> */}
						<div className="external-download-button-brick__inner">
							<span className="external-download-button-brick__button__label">{buttonText}</span>
							<Icon name="sct-5" size="small" />
						</div>
					</a>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ExternalDownloadButton);
