import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import TextArea from 'businessLogic/shared/Input/TextArea';
import {slugify} from '@createandshare/tools';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

/****
Los valores de cada respuesta deben de ser guardado siempre con esta estructura 

			response: {
				[autoID]{
					value
				}
			}
	
**** */

class OpenFieldQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			response: {},
		};
	}

	canContinue = () => {
		if (!get(this.props, 'flexibleContent.required', false) || !isEmpty(this.state.response)) {
			return true;
		}
		return false;
	};

	onchageResponse = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState(
			{
				response: {
					[name]: {
						value,
					},
				},
			},
			this.sendDataQuestion,
		);
	};

	sendDataQuestion = () => {
		const dataState = Object.assign({}, this.state.response);
		const keyData = this.props.flexibleContent._id;
		const type = this.props.flexibleContent.flexibleContentType;
		const name = slugify(this.props.flexibleContent.name);
		if (dataState[keyData]) {
			Object.assign(dataState[keyData], {type}, {name});
			this.props.onFinishQuestion(dataState);
		}
	};

	render() {
		const mainStyleClass = 'open-field-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		const {
			flexibleContent: {statement, description, _id},
		} = this.props;

		return (
			<div className={classes}>
				<div className="row row--nowrap--lg">
					<div className="col-xs-12 col-lg1440-5">
						<div className={mainStyleClass + '__statement swiper-no-swiping'}>
							<FrontEditableText
								text={statement}
								fieldName="statement"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
						</div>
						<div className={mainStyleClass + '__description swiper-no-swiping'}>
							<FrontEditableText
								text={description}
								fieldName="description"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
						</div>
					</div>

					<div className="col-xs-12 col-lg1440-6 col-lg1440-offset-1 ">
						<TextArea
							ref={this.textAreaRef}
							name={_id.toString()}
							className={mainStyleClass + '__text-area swiper-no-swiping'}
							type="text"
							theme="brick-survey"
							value={this.state.value}
							onBlur={(event) => {
								this.props.onTyping(event);
							}}
							// onKeyDown={(event) => {
							// 	this.props.onTyping(event);
							// }}
							onChange={this.onchageResponse}
						/>
					</div>
				</div>
			</div>
		);
	}
}

OpenFieldQuestion.propTypes = {
	flexibleContent: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
	onTyping: PropTypes.func,
};
export default OpenFieldQuestion;
