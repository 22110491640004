import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {imageUrl} from 'businessLogic/helpers/images';
import {getRgbaString} from 'businessLogic/helpers/functions';
import './styles.scss';

class Container extends PureComponent {
	render() {
		const {className, size, styleConfig, withMargin} = this.props;
		const {
			paddingTop,
			paddingBottom,
			marginTop,
			marginBottom,
			colorBg,
			imageBg,
			textColor,
			colorLayerOpacity,
			zIndex,
			overflow,
			borderRadius,
		} = styleConfig;
		let brickTheme;
		if (this.props.styleConfig) {
			brickTheme = this.props.styleConfig.themeSelected;
		}

		const classes = classNames({
			container: true,
			[`${size}`]: true,
			[`theme-${brickTheme}`]: brickTheme !== '',
			[`${className}`]: true,
		});
		let styles = {
			container: {
				margin: withMargin === false ? '0 auto' : '',
			},
			overlay: {},
			imgBg: {},
		};

		if (paddingBottom) styles.container.paddingBottom = `${paddingBottom}px`;
		if (paddingTop) styles.container.paddingTop = `${paddingTop}px`;
		if (marginTop) styles.container.marginTop = `${marginTop}px`;
		if (marginBottom) styles.container.marginBottom = `${marginBottom}px`;
		if (zIndex) styles.container.zIndex = zIndex;
		if (overflow) styles.container.overflow = overflow;
		if (borderRadius) styles.container.borderRadius = `${borderRadius}px`;
		if (textColor) styles.container.color = getRgbaString(textColor);
		if (textColor && textColor === true) styles.container.color = 'initial';
		if (colorBg) styles.imgBg.backgroundColor = getRgbaString(colorBg);
		if (colorBg && colorBg === true) styles.imgBg.backgroundColor = 'transparent';

		if (colorLayerOpacity) styles.overlay.backgroundColor = getRgbaString(colorLayerOpacity);

		if (colorLayerOpacity && colorLayerOpacity === true)
			styles.overlay.backgroundColor = 'transparent';

		if (imageBg) styles.imgBg.backgroundImage = `url('${imageUrl(imageBg)}')`;

		const container = (
			<div ref="container" className={classes} style={styles.container}>
				{this.props.children}
				<div className="container__img-bg" style={styles.imgBg} />
				<div className="container__overlay" style={styles.overlay} />
			</div>
		);

		//Devolvemos el contenedor normal
		return container;
	}
}

Container.propTypes = {
	styleConfig: PropTypes.object,
	withMargin: PropTypes.bool,
	paddingTop: PropTypes.number,
	paddingBottom: PropTypes.number,
	colorBg: PropTypes.object,
	imageBg: PropTypes.object,
	textColor: PropTypes.object,
	colorLayerOpacity: PropTypes.object,
	zIndex: PropTypes.number,
	overflow: PropTypes.string,
	borderRadius: PropTypes.number,
};

Container.defaultProps = {
	styleConfig: {},
	withMargin: true,
	className: '',
};

export default Container;
