import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'publicationTitle',
						title: t`Nombre de la publicación`,
						placeholder: t`Introduce el nombre de la publicación`,
						defaultValue: t`Nombre de la publicación`,
					},
					{
						type: 'TextEditor',
						fieldName: 'publicationNumber',
						title: t`Nº de la publicación`,
						placeholder: t`Introduce el nº de la publicación`,
						defaultValue: t`Publicación 1`,
					},
					{
						type: 'TextEditor',
						fieldName: 'publicationSection',
						title: t`Nº de la sección`,
						placeholder: t`Introduce el nº de la sección`,
						defaultValue: t`Sección 1`,
					},
					{
						type: 'Image',
						fieldName: 'publicationImage',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
					},
					{
						type: 'Repeater',
						min: 2,
						max: 10,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'PillSelector',
								fieldName: 'pillLink',
								title: t`Página de destino`,
								defaultImage: '/uploads/default-images/csDefault1Opt.svg',
								defaultTitle: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
								defaultText:
									'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
							},
						],
					},
					{
						type: 'Repeater',
						max: 10,
						fieldName: 'logos',
						blockTitle: t`Logos`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/logo-example.svg',
							},
							{
								type: 'Text',
								fieldName: 'link',
								title: t`Enlace`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Number',
						fieldName: 'delay',
						title: t`Intervalo entre slides (en seg.)`,
						placeholder: t`Aumenta o disminuye`,
						min: 1,
						max: 10,
					},
					{
						type: 'Number',
						fieldName: 'transitionTime',
						title: t`Duración de la transición (en seg.)`,
						placeholder: t`Aumenta o disminuye`,
						min: 1,
						max: 10,
					},
					{
						type: 'Switch',
						fieldName: 'autoplay',
						title: t`Reproducción automática`,
					},
					{
						type: 'Switch',
						fieldName: 'loop',
						title: t`Reproducción en bucle`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
