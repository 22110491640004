import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Icon from 'ui/shared/Icon';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';
import './styles.scss';

class PdfFile extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Descargar recurso interno`;
	static brickIcon = 'pdf';
	static brickImage = brickImage;
	static brickDefaultData = () => ({
		file: {
			src: '/myb-sample.pdf',
			fileName: '',
		},
		button: _('Descargar'),
	});
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	getEncodedUrl = (src) => {
		return src
			.split('/')
			.map((urlComponent) => encodeURIComponent(urlComponent))
			.join('/');
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const {interactionColor, lightFontColor, darkFontColor} = entityManager.getCustomStyles();
		const bgColor = styles.bgColor;

		const bgColorObject = bgColor ? getColorObject(bgColor) : getColorObject(interactionColor);

		const textColorObject = bgColorObject.tinycolor.isDark()
			? getColorObject(lightFontColor)
			: getColorObject(darkFontColor);

		const file = data.file;
		const buttonText = data.button;

		const classes = classNames({
			'brick': true,
			'pdf-file-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{file.fileName !== '' ? (
						<a
							className="pdf-file-brick__button"
							href={this.getEncodedUrl(file.src)}
							download={file.fileName}
							onClick={() => {
								this.props.brickEvent('click');
							}}
							style={{
								backgroundColor: bgColorObject.hex,
								color: textColorObject.hex,
							}}
						>
							{/* <div className="pdf-file-brick__pulse" /> */}
							<div className="pdf-file-brick__inner">
								<span className="pdf-file-brick__button__label">{buttonText}</span>
								<Icon name="download" size="small" />
							</div>
						</a>
					) : (
						<a
							className="pdf-file-brick__button"
							href={this.getEncodedUrl(file.src)}
							download
							onClick={() => {
								this.props.brickEvent('click');
							}}
							style={{
								backgroundColor: bgColorObject.hex,
								color: textColorObject.hex,
							}}
						>
							{/* <div className="pdf-file-brick__pulse" /> */}
							<div className="pdf-file-brick__inner">
								<span className="pdf-file-brick__button__label">{buttonText}</span>
								<Icon name="download" size="small" />
							</div>
						</a>
					)}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(PdfFile);
