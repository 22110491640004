import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddBrickButton from 'businessLogic/core/shared/Buttons/AddBrickButton';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import adminPerms from '@createandshare/adminperms';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class MainContainerBricks extends PureComponent {
	constructor(props) {
		super(props);

		this.brickRef = React.createRef();
	}

	componentDidMount() {
		this.registerHandler = this.props.configObject.registerConfig(
			this.props.pill.config,
			this.brickRef.current,
		);
	}
	componentWillUnmount() {
		this.props.configObject.unregisterConfig(this.brickRef.current);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pill.config !== this.props.pill.config) {
			this.props.configObject.updateRegisteredConfig(this.brickRef.current, this.props.pill.config);
		}
	}

	addBrick = () => {
		this.props.addBrick(this.props.brickId, 0);
	};

	openAIPanel = () => {
		this.props.openAIPanel();
	};

	render() {
		let numberOfBrick = this.props.brick.children.default.length;
		const intro = {
			title: t`Empieza a crear`,
			text: t`Sitúa el ratón sobre este texto y haz click en el icono + que aparece debajo para añadir tu primer ladrillo`,
		};

		const canUseAI = adminPerms.canUseAI() || adminPerms.isSuperAdmin();

		return (
			<div className="main-container-rows-brick" ref={this.brickRef}>
				{this.props.editMode && (
					<div className="first-brick">
						{numberOfBrick === 0 && this.props.editMode ? (
							<div className="main-container-rows-brick__help-text">
								<h2>{intro.title}</h2>
								<p>{intro.text}</p>
								{canUseAI && (
									<>
										<p>{t`o`}</p>
										<Button
											variant="contained"
											color="secondary"
											onClick={this.openAIPanel}
											sx={{
												'backgroundColor': '#fff',
												'color': '#000',
												'&:hover': {
													backgroundColor: '#fff',
												},
											}}
										>
											{t`Crea un borrador con IA`}
										</Button>
									</>
								)}
							</div>
						) : (
							''
						)}

						<div className="brick-add-button">
							<AddBrickButton ref="addBrickButton" addBrick={this.addBrick} />
						</div>
					</div>
				)}
				{/* {this.props.editMode && <AddBrickButton ref="addBrickButton" addBrick={this.addBrick} />} */}
				{this.props.renderChildren('default', {
					includeAddBrickButton: true,
					includeEditButtons: true,
				})}
			</div>
		);
	}
}

MainContainerBricks.propTypes = {
	brick: PropTypes.object,
};

export default brickWrapper(MainContainerBricks);
