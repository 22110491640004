import React, {useRef, useEffect} from 'react';
import {useFocused} from 'slate-react';
// import {Transforms} from 'slate';
// import {Editor, Range} from 'slate';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ToggleButton from './components/basics/ToggleButton';

import Divider from './components/basics/Divider';
import ToggleMarkButton from './components/ToggleMarkButton';
import ToggleBlockButton from './components/ToggleBlockButton';

// Alignment
import SetAlignmentButton from './components/SetAlignmentButton';
// import SetBlockTypeButton from './components/SetBlockTypeButton';

// List
import SetListButton from './components/SetListButton';

// FontSize
import IncreaseFontSizeButton from './components/IncreaseFontSizeButton';

// Indent
import IncreaseIndentButton from './components/IncreaseIndentButton';

// History
import UndoButton from './components/UndoButton';
import RedoButton from './components/RedoButton';

// Links
import AddLinkButton from './components/AddLinkButton';

// Wiki
import AddWikiButton from './components/AddWikiButton';

// Color
import ColorButton from './components/ColorButton';

// Icons
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

// AI
import AIButton from './components/AIButton';

import adminPerms from '@createandshare/adminperms';

const HoveringToolbar = ({open, anchorEl, editor, onClose = () => {}}) => {
	const ref = useRef();
	const inFocus = useFocused();
	const [expanded, setExpanded] = React.useState(false);

	const id = open ? 'hovering-toolbar' : undefined;

	const canUseAI = adminPerms.canUseAI() || adminPerms.isSuperAdmin();

	// Si inFocus cambia y es true, lanzamos un evento para que el resto de editores de la página se desenfoquen
	// y eliminen la selección
	useEffect(() => {
		if (inFocus) {
			const event = new CustomEvent('editorFocus', {detail: {editor}});
			window.dispatchEvent(event);
		}
	}, [inFocus, editor]);

	return (
		// <Portal>
		<Popper
			id={id}
			open={open}
			anchorEl={anchorEl}
			placement="top"
			disablePortal
			// modifiers={{
			// 	offset: {
			// 		enabled: true,
			// 		offset: '0,8',
			// 	},
			// }}
			modifiers={[
				{
					name: 'offset',
					enabled: true,
					options: {
						offset: [0, 8],
					},
				},
				{
					name: 'preventOverflow',
					enabled: true,
					options: {
						altAxis: true,
						altBoundary: false,
						tether: true,
						rootBoundary: 'viewport',
						padding: 8,
					},
				},
			]}
			sx={{
				zIndex: 1,
			}}
		>
			<ClickAwayListener onClickAway={onClose}>
				<Paper
					ref={ref}
					sx={{
						transition: 'opacity 0.75s',
						backgroundColor: '#ffffff',
						padding: '5px 5px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
						flexWrap: 'wrap',
						maxWidth: expanded ? 450 : undefined,
					}}
				>
					<UndoButton />
					<RedoButton />
					<Divider />
					<ToggleMarkButton format="bold" />
					<ToggleMarkButton format="italic" />
					<ToggleMarkButton format="underlined" />
					{expanded && (
						<>
							<ToggleMarkButton format="strikethrough" />
							<ToggleMarkButton format="superscript" />
							<ToggleMarkButton format="subscript" />
						</>
					)}
					<Divider />
					<SetAlignmentButton />
					{expanded && (
						<>
							<IncreaseIndentButton step={1} />
							<IncreaseIndentButton step={-1} />
						</>
					)}
					<IncreaseFontSizeButton step={0.1} />
					<IncreaseFontSizeButton step={-0.1} />
					<ColorButton format="color" />
					<ColorButton format="highlightColor" />

					{/* <SetBlockTypeButton /> */}
					<Divider />

					{expanded && (
						<>
							<SetListButton />
							<ToggleBlockButton format="blockquote" />
							<ToggleBlockButton format="codeblock" />
							<Divider />
						</>
					)}
					<AddLinkButton />
					{/* <RemoveLinkButton /> */}
					{/* <Divider /> */}
					<AddWikiButton />
					{/* <RemoveWikiButton /> */}
					{canUseAI && <AIButton />}
					<Divider />
					<ToggleButton
						value="expanded"
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						{expanded ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
					</ToggleButton>
				</Paper>
			</ClickAwayListener>
			{/* </Portal> */}
		</Popper>
	);
};

export default HoveringToolbar;
