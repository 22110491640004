import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
// import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import './styles.scss';

class ContitionalSwitch extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	setValue = (value) => {
		const mainField = this.props.getMainField();
		this.props.setMainField({...mainField, switchValue: value});
	};

	handleToggle = () => {
		const mainField = this.props.getMainField();
		const switchValue = mainField?.switchValue;
		this.setValue(!switchValue);
	};

	render() {
		const content = this.props.editionBrickStruct.content;
		const mainField = this.props.getMainField();
		const switchValue = mainField?.switchValue;
		return (
			<React.Fragment>
				<div className="edition-brick-conditional-switch">
					<label
						className="edition-brick-conditional-switch__label"
						title={this.props.editionBrickStruct.instructions}
					>
						{this.props.editionBrickStruct.title}
					</label>
					<div
						className={'edition-brick-conditional-switch__button ' + (switchValue ? 'active' : '')}
					>
						<span className="edition-brick-conditional-switch__button__state">
							{switchValue ? 'On' : 'Off'}
						</span>
						<div
							className="edition-brick-conditional-switch__button__btn-switch"
							onClick={this.handleToggle}
						/>
					</div>
				</div>
				{switchValue && (
					<div className="edition-brick-conditional-switch__content">
						{this.props.renderChildren(content)}
					</div>
				)}
				{/* {this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)} */}
			</React.Fragment>
		);
	}
}

export default editionBrickWrapper(ContitionalSwitch);
