import gql from 'graphql-tag';

export const Permissions = gql`
	fragment Permissions on AdminPermissions {
		viewPlatform
		lms
		lms_notification_stats
		lms_reports
		lms_feedback
		lms_gamification
		config
		config_general
		config_general_logo
		config_general_styles
		config_general_socialNetworks
		config_general_analytics
		config_general_comments
		config_general_calendar
		config_legals
		home_events_section
		home
		home_slider
		loginRegist
		loginRegist_text
		loginRegist_backgroundImage
		pwa
		pwa_name
		pwa_icon
		pwa_color
		notifications
		notifications_mailData
		notifications_sendType
		calendar
		users_view
		users_view_user_groups
		users_view_user_platform
		users_view_user_statistics
		users_view_user_content
		users_edit_notifications_settings
		users_edit_lang
		users_create
		users_edit
		users_delete
		users_export
		admins_view
		admins_create
		admins_edit
		admins_delete
		admins_export
		groups_view
		groups_create
		groups_edit
		groups_delete
		groups_export
		pills_view
		pills_create
		pills_edit
		pills_editContent
		pills_delete
		pills_duplicate
		pills_publish
		pills_editAccess
		pills_editAccess_dynamicGroups
		pills_share
		pills_enable_feedback
		pills_enable_rrss
		pills_enable_comments
		pills_enable_goals
		pills_notifications_view
		pills_notifications_create
		pills_set_order
		taxonomies_view
		taxonomies_create
		taxonomies_edit
		taxonomies_delete
		admin_roles_edit
		admin_managers_view
		admin_managers_edit
		admin_users_edit
		admin_groups_edit
		admin_sections_edit
		admin_allUsers_edit
		admin_allGroups_edit
		admin_allSections_edit
		entity_update
		entity_set_data
		add_admin_to_entity
		delete_admin_from_entity
		view_other_admins_media
		pills_create_from_library
		pills_share_own_pills
		pills_view_description
		pills_view_image
		pills_view_creator
		pills_personalize
		pills_editContent_at_creation
		pills_enable_rrss_share
		pills_generate_scorm
		pills_use_ai
		goals_view
		goals_create
		goals_edit
		goals_delete
	}
`;
