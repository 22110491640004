import {getRgbaString} from 'businessLogic/helpers/functions';

const getFinalChildren = (children) => {
	const finalChildren = [];

	for (const child of children) {
		// Si es un array de objetos, los añade al array final
		if (Array.isArray(child)) {
			finalChildren.push(...child);
		} else {
			// Si no, lo añade como un objeto
			finalChildren.push(child);
		}
	}

	return finalChildren;
};

function htmlToSlate(quillHtml) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(quillHtml, 'text/html');
	const body = doc.body;

	function walk(node) {
		if (node.nodeType === Node.TEXT_NODE) {
			return {text: node.nodeValue};
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			let children = [];
			for (const child of node.childNodes) {
				children.push(walk(child));
			}

			switch (node.tagName) {
				case 'P': {
					// Si la clase empieza por ql-align, es un párrafo con alineación
					let align;
					if (node.className.startsWith('ql-align')) {
						align = node.className.replace('ql-align-', '');
					}

					let indent;
					if (node.className.startsWith('ql-indent')) {
						indent = parseInt(node.className.replace('ql-indent-', ''));
					}

					return {type: 'paragraph', children: getFinalChildren(children), align, indent};
				}
				case 'STRONG': {
					const finalChildren = getFinalChildren(children);
					// Si es un span con style: color, lo convierte a color
					let color = node?.style?.color;

					return finalChildren.map((child) => ({...child, bold: true, color}));
				}
				case 'EM': {
					const finalChildren = getFinalChildren(children);
					// Si es un span con style: color, lo convierte a color
					let color = node?.style?.color;

					return finalChildren.map((child) => ({...child, italic: true, color}));
				}
				case 'U': {
					const finalChildren = getFinalChildren(children);
					// Si es un span con style: color, lo convierte a color
					let color = node?.style?.color;

					return finalChildren.map((child) => ({...child, underlined: true, color}));
				}
				case 'MARK': {
					const finalChildren = getFinalChildren(children);
					let color = 'var(--highlightColor)';

					return finalChildren.map((child) => ({...child, color}));
				}
				case 'SPAN': {
					const finalChildren = getFinalChildren(children);
					// Si es un span con style: color, lo convierte a color
					let color = node?.style?.color;

					return finalChildren.map((child) => ({...child, color}));
				}
				case 'OL':
					return {
						type: 'numbered-list',
						children: getFinalChildren(children),
					};
				case 'UL':
					return {
						type: 'bulleted-list',
						children: getFinalChildren(children),
					};
				case 'LI': {
					return {type: 'list-item', children: getFinalChildren(children)};
				}
				case 'A': {
					return {
						type: 'link',
						url: node.getAttribute('href'),
						children: getFinalChildren(children),
					};
				}
				case 'WIKI':
					return {
						type: 'wiki',
						wikiData: {
							text: node.getAttribute('data-text'),
							image: node.getAttribute('data-image'),
							imageCredits: node.getAttribute('data-img-credits'),
						},
						children: getFinalChildren(children),
					};
				default:
					if (children.length === 1) {
						return children[0];
					}
					if (children.length === 0) {
						return {text: ''};
					}
					return children;
			}
		}
	}

	let slate = [];
	for (const child of body.childNodes) {
		slate.push(walk(child));
	}

	return slate;
}

const getColor = (colorObject) => {
	if (!colorObject) {
		return undefined;
	}

	if (typeof colorObject === 'string') {
		return colorObject;
	} else if (typeof colorObject === 'object') {
		const defaultColor = getRgbaString(colorObject?.rgb) || colorObject?.hex;
		if (colorObject?.cssVar) {
			return `var(${colorObject.cssVar}, ${defaultColor})`;
		} else {
			return defaultColor;
		}
	}
};

function slateToHtml(slate) {
	function walk(node) {
		if (node.text) {
			let output = node.text;
			const styles = {};
			if (node.bold) {
				output = `<strong>${output}</strong>`;
			}

			if (node.italic) {
				output = `<em>${output}</em>`;
			}

			if (node.underlined) {
				output = `<u>${output}</u>`;
			}

			if (node.strikethrough) {
				output = `<s>${output}</s>`;
			}

			if (node.code) {
				output = `<code>${output}</code>`;
			}

			if (node.quote) {
				output = `<blockquote>${output}</blockquote>`;
			}

			if (node.subscript) {
				output = `<sub>${output}</sub>`;
			}

			if (node.superscript) {
				output = `<sup>${output}</sup>`;
			}

			if (node.color) {
				styles.color = getColor(node.color);
			}

			if (node.highlightColor) {
				styles['background-color'] = getColor(node.highlightColor);
			}

			if (node.fontSize) {
				styles['font-size'] = node.fontSize;
			}

			if (node.emFontSize && node.emFontSize !== 1) {
				styles['font-size'] = `${node.emFontSize}em`;
			}

			if (node.fontFamily) {
				styles['font-family'] = node.fontFamily;
			}

			const styleEntries = Object.entries(styles);

			if (styleEntries.length > 0) {
				return `<span style="${styleEntries
					.map(([key, value]) => `${key}: ${value}`)
					.join('; ')}">${output}</span>`;
			}

			return output;
		} else if (node.children) {
			let children = [];
			for (const child of node.children) {
				children.push(walk(child));
			}

			let className = '';
			if (node.align) {
				className += `ql-align-${node.align} `;
			}

			if (node.indent) {
				className += `ql-indent-${node.indent} `;
			}

			switch (node.type) {
				case 'heading-one':
					return `<h1 class="${className}">${children.join('')}</h1>`;
				case 'heading-two':
					return `<h2 class="${className}">${children.join('')}</h2>`;
				case 'heading-three':
					return `<h3 class="${className}">${children.join('')}</h3>`;
				case 'heading-four':
					return `<h4 class="${className}">${children.join('')}</h4>`;
				case 'heading-five':
					return `<h5 class="${className}">${children.join('')}</h5>`;
				case 'heading-six':
					return `<h6 class="${className}">${children.join('')}</h6>`;
				case 'numbered-list':
					return `<ol class="${className}">${children.join('')}</ol>`;
				case 'bulleted-list':
					return `<ul class="${className}">${children.join('')}</ul>`;
				case 'list-item':
					return `<li class="${className}">${children.join('')}</li>`;
				case 'link':
					return `<a class="my-link" href="${
						node.url
					}" target='_blank' rel="noopener noreferrer">${children.join('')}</a>`;
				case 'wiki':
					return `<wiki class="my-wiki" data-text="${node.wikiData.text || ''}" data-image="${
						node.wikiData.imageUrl || ''
					}" data-img-credits="${node.wikiData.imageCredits || ''}">${children.join('')}</wiki>`;
				case 'paragraph':
				default: {
					let innerHtml = children.join('');
					if (innerHtml === '') {
						innerHtml = '<br>';
					}
					return `<p class="${className}">${innerHtml}</p>`;
				}
			}
		}
	}

	let html = '';
	for (const child of slate) {
		html += walk(child);
	}

	return html;
}

export {htmlToSlate, slateToHtml};
