import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Vimeo from '@u-wave/react-vimeo';
// https://github.com/u-wave/react-vimeo
import './styles.scss';
import PropTypes from 'prop-types';

class VimeoBackground extends PureComponent {
	render() {
		const {video, paused, muted, videoLoop} = this.props;
		return (
			<div className="video-vimeo-container-background" ref="videoContainer" key={video}>
				<Vimeo
					video={video}
					paused={paused}
					background={true}
					showTitle={false}
					muted={muted}
					showPortrait={false}
					loop={videoLoop}
					showByline={false}
				/>
			</div>
		);
	}
}

VimeoBackground.propTypes = {
	video: PropTypes.string,
	paused: PropTypes.bool,
};

export default brickWrapper(VimeoBackground);
