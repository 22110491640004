import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 3,
						// max: 10,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault1Opt.svg',
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Switch',
						fieldName: 'showMainImageComplete',
						title: t`Mostrar imagen principal completa`,
					},
					{
						type: 'Switch',
						fieldName: 'showSecondaryComplete',
						title: t`Mostrar imágenes secundarias completas`,
					},
					{
						type: 'Switch',
						fieldName: 'disableInfiniteLoop',
						title: t`Desactivar bucle infinito`,
					},
				],
			},
		],
	},
];

export default editionFields;
