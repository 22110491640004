import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {routerMiddleware} from './middlewares/routerMiddleware';
import {createBrowserHistory} from 'history';
import {startListener} from './listener';

//Importamos los reducer
import routerReducer from './data/router';
import pillsReducer from './data/pills';
import scenesReducer from 'businessLogic/scope/admin/pages/reducer';
import sessionReducer from './data/session';
import thunkMiddleware from 'redux-thunk';

import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink, from} from 'apollo-link';
import {onError} from 'apollo-link-error';
// import {RetryLink} from 'apollo-link-retry';
import {InMemoryCache} from 'apollo-cache-inmemory';
import Logger from 'businessLogic/services/Logger';

// Comprobamos si se ha incluido el parámetro aau para indicar que es un admin entrando como usuario
const urlParams = new URLSearchParams(window.location.search);
export const adminAsUserToken = urlParams.has('aau') ? urlParams.get('aau') : null;

const errorLink = onError(({graphQLErrors, networkError, operation, response}) => {
	try {
		if (graphQLErrors) {
			Logger.setContext('operation', operation);
			Logger.debug('operation', operation);
			Logger.debug('response', response);
			const errors = [];
			graphQLErrors.forEach(({message, locations, path}) => {
				const error = `[Error GraphQL]: Message: ${message}, Location: ${locations}, Path: ${path}`;
				errors.push(error);
				Logger.error(error);

				// Aquí puedes añadir tu lógica personalizada para manejar errores
			});
			Logger.captureError(errors.join('\n'));
		}

		if (networkError) {
			Logger.error(`[Error de Red]: ${networkError}`);
			Logger.debug('operation', operation);
			Logger.debug('response', response);
			Logger.setContext('operation', operation);
			Logger.captureException(networkError);
			// Aquí puedes manejar errores de red
		}
	} catch (e) {
		Logger.captureException(e);
	}
});

// const retryLink = new RetryLink({
// 	delay: {
// 		initial: 300, // milisegundos antes del primer reintento
// 		max: 3000, // máximo tiempo entre reintentos
// 		jitter: true, // añade variación aleatoria al tiempo
// 	},
// 	attempts: {
// 		max: 3, // número máximo de intentos
// 		retryIf: (error, operation) => {
// 			Logger.log('error', error);
// 			Logger.log('operation', operation);
// 			return false;
// 			// Reintenta solo para errores de red o queries específicas
// 			const shouldRetry =
// 				!!error &&
// 				operation.operationName !== 'logout' && // no reintentar logout
// 				(error.networkError ||
// 					(error.graphQLErrors &&
// 						error.graphQLErrors.some((err) => err.extensions?.code === 'INTERNAL_SERVER_ERROR')));

// 			Logger.log(`Reintentando operación ${operation.operationName}: ${shouldRetry}`);
// 			return shouldRetry;
// 		},
// 	},
// });

const getGraphqlLink = () => {
	const httpLink = new HttpLink({
		uri: '/graphql',
		credentials: 'include',
	});

	if (adminAsUserToken) {
		const adminAsUserMiddleware = new ApolloLink((operation, forward) => {
			operation.setContext({
				headers: {
					CustomHeaderAdminAsUser: adminAsUserToken,
				},
			});
			return forward(operation);
		});

		// return from([errorLink, retryLink, adminAsUserMiddleware, httpLink]);
		return from([errorLink, adminAsUserMiddleware, httpLink]);
	}
	// return from([errorLink, retryLink, httpLink]);
	return from([errorLink, httpLink]);
};

// Creamos el objeto history
export const history = createBrowserHistory();

const middlewares = [thunkMiddleware];

//Función generadora de ids únicos
function* idGen() {
	var index = 0;
	while (true) yield index++;
}

const gen = idGen();

const apolloOptions = {
	// link: apollo_client,
	link: getGraphqlLink(),
	cache: new InMemoryCache({
		dataIdFromObject: (object) => {
			if (object.__typename === 'BrickType') {
				//A los objetos de tipo BrickType le damos ids únicos siempre, porque sus
				// ids no son globales sino que se pueden repetir entre píldoras
				return gen.next().value;
			}
			return object.id;
		},
	}),
};
if (process.env.REACT_APP_EVENTS_API === 'scorm') {
	apolloOptions.defaultOptions = {
		query: {
			fetchPolicy: 'cache-only',
		},
		watchQuery: {
			fetchPolicy: 'cache-only',
		},
	};
} else {
	//Cuando no estamos en scorm, necesitamos el routerMiddleware para que cambie la url visible
	// Creamos el router Middleware
	const routerMid = routerMiddleware(history);
	middlewares.push(routerMid);
}

export const client = new ApolloClient(apolloOptions);

const dataGeneral = combineReducers({
	router: routerReducer,
	pills: pillsReducer,
	scenes: scenesReducer,
	session: sessionReducer,
});

const reducers = combineReducers({
	dataGeneral,
});

// Creamos el store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middlewares)));

// Lanzamos el listener del objeto history que modificará el store ante los cambios en la url
//al tener dispatch debe ir después de la definición del store
if (process.env.REACT_APP_EVENTS_API !== 'scorm') {
	startListener(history, store);
}

export default store;
