import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						title: t`Imagen`,
						fieldName: 'image',
					},
					{
						type: 'TextEditor',
						fieldName: 'beforeHeading',
						title: t`Título`,
						placeholder: t`Introduce un antetítulo`,
					},
					{
						type: 'TextArea',
						fieldName: 'title',
						title: t`Texto`,
						placeholder: t`Introduce un título`,
						rows: 2,
					},
					{
						type: 'Text',
						fieldName: 'textButtonStart',
						title: t`Texto del botón de inicio del test`,
						placeholder: t`Introduce el texto del botón de inicio del test`,
					},
					{
						type: 'Text',
						fieldName: 'textLinkInstructions',
						title: t`Texto del enlace de instrucciones`,
						placeholder: t`Añade texto para el enlace de intrucciones de tu test`,
					},
					{
						type: 'TextArea',
						fieldName: 'instructions',
						title: t`Instrucciones`,
						placeholder: t`Añade un texto de ayuda para tus usuarios, si lo consideras necesario`,
						rows: 2,
					},
					{
						type: 'Text',
						fieldName: 'textButtonAgain',
						title: t`Texto del botón para repetir el test`,
						placeholder: t`Introduce el texto del botón`,
					},
					{
						type: 'Text',
						fieldName: 'textButtonShowsolutions',
						title: t`Texto del botón de ver resultados`,
						placeholder: t`Introduce el texto del botón`,
					},

					{
						type: 'Repeater',
						min: 1,
						fieldName: 'questions',
						blockTitle: t`Pregunta`,
						content: [
							// {
							// 	type: 'Text',
							// 	fieldName: 'name',
							// 	title: t`Nombre del Contenido`,
							// 	placeholder: t`Introduce el nombre de este contenido`,

							// 	instructions: t`Este nombre no se mostrará en el test. Es el nombre con el que posteriormente identificarás tu pregunta.`,
							// },
							{
								type: 'TextEditor',
								fieldName: 'statement',
								title: t`Pregunta`,
								placeholder: t`Introduce una pregunta`,
							},
							{
								type: 'Image',
								fieldName: 'imgStatement',
								defaultImage: '/uploads/default-images/csDefault3Opt.svg',
								footer: true,
							},
							{
								type: 'Repeater',
								min: 2,
								max: 4,
								defaultNumber: 3,
								fieldName: 'answers',
								blockTitle: t`Respuesta`,
								content: [
									{
										type: 'SwitchTest',
										fieldName: 'valueSolution',
										defaultValue: false,
									},
									{
										type: 'TextArea',
										fieldName: 'answer',
										title: t`Texto`,
										placeholder: t`Introduce el texto de la opción`,
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Información`,
				fieldName: 'config',
				content: [
					{
						type: 'TextArea',
						fieldName: 'brickName',
						title: t`Nombre del Test`,
						placeholder: t`Introduce un titulo para el test`,
						instructions: t`El nombre del test (no visible para el usuario) te ayudará a identificarlo en el panel de administración.`,
						rows: 1,
					},
					{
						type: 'TextArea',
						fieldName: 'brickDescription',
						title: t`Descripción del Test`,
						placeholder: t`Introduce una descripción para el test`,
						instructions: t`La descripción del test (no visible para el usuario) te ayudará a identificarlo en el panel de administración.`,
						rows: 4,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Number',
						fieldName: 'pointSuccess',
						title: t`Puntos por pregunta acertada`,
						placeholder: t`Aumenta o disminuye de 10 en 10`,
						min: 10,
						max: 100,
						step: 10,
					},
					{
						type: 'Number',
						fieldName: 'markCutOff',
						title: t`Nota de corte en %`,
						placeholder: t`Aumenta o disminuye de 10 en 10`,
						min: 10,
						max: 100,
						step: 10,
					},
					{
						type: 'Number',
						fieldName: 'maxAttempts',
						title: t`Número máximo de intentos`,
						placeholder: t`Aumenta o disminuye`,
						min: 1,
						max: 100,
					},
					{
						type: 'Number',
						fieldName: 'duration',
						title: t`Duración máxima del examen en minutos`,
						placeholder: t`Aumenta o disminuye`,
						min: 1,
						max: 60,
					},
					{
						type: 'Switch',
						fieldName: 'requiredActions',
						title: t`Si el test está incluido en un curso, ¿Es obligatorio contestar para completar la lección?`,
						instructions: t`Si el test está incluido en un curso, ¿es obligatorio contestarlo para completar la lección?`,
						defaultValue: true,
					},
					{
						type: 'Switch',
						fieldName: 'wrongAnswers',
						title: t`¿Las respuestas incorrectas restan puntos?`,
						instructions: t`¿Las respuestas incorrectas restan puntos?`,
						defaultValue: true,
					},
					{
						type: 'Switch',
						fieldName: 'showSolutions',
						title: t`¿Mostrar soluciones aunque no haya aprobado cuando ya no queden intentos?`,
						instructions: t`¿Mostrar soluciones aunque no haya aprobado cuando ya no queden intentos?`,
						defaultValue: false,
					},
					{
						type: 'ConditionalSwitch',
						fieldName: 'customizeResultTexts',
						title: t`¿Personalizar los textos de los resultados?`,
						instructions: t`¿Personalizar los textos de los resultados?`,
						defaultValue: false,
						content: [
							{
								type: 'TextEditor',
								fieldName: 'successText',
								title: t`Mensaje de aprobado`,
								placeholder: t`Introduce un mensaje de aprobado`,
							},
							{
								type: 'Image',
								title: t`Imagen de aprobado`,
								fieldName: 'imageSuccess',
							},
							{
								type: 'TextEditor',
								fieldName: 'failText',
								title: t`Mensaje de suspenso`,
								placeholder: t`Introduce un mensaje de suspenso`,
							},
							{
								type: 'Image',
								title: t`Imagen de suspenso`,
								fieldName: 'imageFail',
							},
						],
					},
				],
			},
		],
	},
];
export default editionFields;
