import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.png';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import editionFields from './edit.js';
import './styles.scss';

class ScrollPause extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Pausar scroll automático`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	handleVisibilityChange(isVisible) {
		if (isVisible) {
			if (this.props.configObject) {
				this.props.configObject.stopScrollAnimation();
				this.props.brickEvent('scrollAutoPause');
			}
		}
	}

	render() {
		const styles = this.props.brick.styles || {};

		if (!styles.containerStyle) {
			styles.containerStyle = {};
		}

		if (!styles.containerStyle.marginTop) {
			styles.containerStyle.marginTop = -10;
		}
		if (!styles.containerStyle.marginBottom) {
			styles.containerStyle.marginBottom = -10;
		}

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			/>
		);
	}
}

export default brickWrapper(ScrollPause);
