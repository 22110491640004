import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 1,
						fieldName: 'blocks',
						blockTitle: t`Bloque`,
						content: [
							{
								type: 'TextEditor',
								fieldName: 'title',
								title: t`Título`,
								placeholder: t`Introduce el título`,
								defaultValue:
									'<p><span style=""><strong>Dusue nobistamanurs saeamos</strong></span></p>',
							},
							{
								type: 'TextEditor',
								fieldName: 'text',
								title: t`Texto`,
								placeholder: t`Introduce el texto`,
								defaultValue:
									'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut auctor, nunc sit amet luctus ultrices, neque sapien aliquam nunc, a tincidunt nisl nisl vel diam. Etiam vitae magna sit amet odio tincidunt placerat. Nulla facilisi. Nulla facilisi. Sed in libero eget orci ultricies aliquam. Nulla facilisi. Nulla facilisi. Sed in libero eget orci ultricies aliquam.',
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: 'Estilos',
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						label: t`Color del acordeón`,
						fieldName: ['accordionBGColor'],
					},
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
