import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageCmp from 'businessLogic/shared/Image/NewImageCmp';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

import BricksContainer from './components/BricksContainer';

import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';

class AccordionBig extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Acordeón grande`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		blocks: [
			{
				// title: 'Dusue nobistamanurs saeamos',
				_id: 'block_1',
				title: {
					slateValue: [
						{
							type: 'paragraph',
							children: [
								{
									text: 'Dusue nobistamanurs saeamos',
									bold: true,
								},
							],
						},
					],
					textValue: '<p><span style=""><strong>Dusue nobistamanurs saeamos</strong></span></p>',
				},
				image: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
					footer: '',
				},
			},
		],
	};
	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			expanded: null,
		};
	}

	handleChange = (panel) => (event, newExpanded) => {
		this.setState({expanded: newExpanded ? panel : false});
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const classes = classNames({
			'brick': true,
			'accordion-big-brick': true,
		});

		const blocks = data.blocks || [];

		const {highlightColor, lightFontColor, darkFontColor} = entityManager.getCustomStyles();

		const selectedBGColor = styles.accordionBGColor;
		const selectedInnerBGColor = styles.accordionInnerBGColor;

		const bgColorObject = selectedBGColor
			? getColorObject(selectedBGColor)
			: getColorObject(highlightColor);

		const bgColorRGB = bgColorObject.rgb;
		const innerBgColor = selectedInnerBGColor
			? getColorObject(selectedInnerBGColor).hex
			: `rgba(${bgColorRGB.r}, ${bgColorRGB.g}, ${bgColorRGB.b}, ${bgColorRGB.a * 0.7})`;

		const textColorObject = bgColorObject.tinycolor.isDark()
			? getColorObject(lightFontColor)
			: getColorObject(darkFontColor);

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Box
					className={classes}
					sx={{
						'padding': 1,
						'& .MuiAccordion-root': {
							backgroundColor: 'transparent',
						},
					}}
				>
					{blocks.map((block, index) => {
						const includeImage = block.image && block.image.imageSrc;
						return (
							<Accordion
								key={index}
								// expanded={this.state.expanded === 'panel' + index}
								onChange={this.handleChange('panel' + index)}
								sx={{
									// 'backgroundColor': bgColorObject.hex,
									'color': textColorObject.hex,
									'marginBottom': '5px',
									'& .MuiAccordionSummary-expandIconWrapper': {
										color: textColorObject.hex,
									},
									'overflow': 'hidden',
									// '&:before': {
									// 	backgroundColor: bgColorObject.hex,
									// },
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1-content"
									id="panel1-header"
									sx={{
										'backgroundColor': bgColorObject.hex,
										'&:before': {
											backgroundColor: bgColorObject.hex,
										},
										'overflow': 'hidden',
										'& .ql-editor p': {
											fontSize: '1.8rem',
										},
										'paddingLeft': 0,
										'& .MuiAccordionSummary-content': {
											'margin': 0,
											'&.Mui-expanded': {
												margin: 0,
											},
										},
										'&.Mui-focusVisible': {
											backgroundColor: bgColorObject.hex,
										},
									}}
								>
									{/* La caja debe ocupar todo el alto y mantener un ancho proporcional */}
									{includeImage && (
										<Box
											sx={{
												height: '100%',
												width: '10%',
												minWidth: '100px',
												position: 'absolute',
											}}
										>
											<NewImageCmp image={block.image} imgWidths={640} defaultVisible={true} />
										</Box>
									)}
									<Box
										sx={{
											width: '85%',
											paddingLeft: includeImage ? 'max(10%, 100px)' : 0,
											margin: '12px 0 12px 20px',
										}}
									>
										<FrontEditableText
											fieldName="title"
											text={block.title}
											set={(fieldPath, fieldData) => {
												//Propagamos la llamada al método set añadiendo mi nodo al path
												if (this.props.set)
													this.props.set(['blocks', index].concat(fieldPath), fieldData);
											}}
										/>
									</Box>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										'backgroundColor': innerBgColor,
										'& .container.normal': {
											maxWidth: '100%',
										},
									}}
								>
									{block._id && (
										<BricksContainer
											mainProps={this.props}
											childList={block._id.toString()}
											editMode={this.props.editMode}
										/>
									)}
								</AccordionDetails>
							</Accordion>
						);
					})}

					{/* <Box className={classes + '__title'}>
						<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
					</Box>

					<Box className={classes + '__text'}>
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</Box> */}
				</Box>
			</Container>
		);
	}
}
export default brickWrapper(AccordionBig);
