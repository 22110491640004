//Aquí realizaremos todas las functions genéricas que sean necesarias a lo largo del desarrollo, será nuestra librería de functions

import Logger from 'businessLogic/services/Logger';
import tinycolor from 'tinycolor2';

/**
 * function que me sirve para crear un id único, comprobando si existe en todo el object de ids
 * @param  {Object} state recibimos todo el object con todas las pildoras para a la hora de generar el id checkear que no exista ya.
 */
export const generateId = (state = {}) => {
	let id = createId();
	while (state[id] !== undefined) {
		id = createId();
	}
	return id;
};

/**
 * function que se encarga de devolverme un identificador
 *
 */
const createId = () => {
	return Date.now() * 1000000 + Math.random() * 1000000;
};

/**
 * Devuelve un identificador aleatorio
 *
 */
export const generateUuid = () => {
	return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const stripHtml = (html) => {
	// Create a new div element
	const temporalDivElement = document.createElement('div');
	// Set the HTML content with the providen
	temporalDivElement.innerHTML = html;
	// Retrieve the text property of the element (cross-browser support)
	return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const getPlainTextFromTextEditorObject = (textObject) => {
	if (typeof textObject === 'string') {
		return textObject;
	}

	if (typeof textObject === 'object' && textObject.textValue) {
		return stripHtml(textObject.textValue);
	}
};

export const cancelablePromise = (promise) => {
	let hasCanceled_ = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			(val) => (hasCanceled_ ? reject({isCanceled: true}) : resolve(val)),
			(error) => (hasCanceled_ ? reject({isCanceled: true}) : reject(error)),
		);
	});

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
	};
};

/**
 * function que se encarga de comprobar si el navegador utilizado el IE11
 */
export const checkVersionIE = () => {
	//Fallback para IE 11
	const sAgent = window.navigator.userAgent;
	const Idx = sAgent.indexOf('MSIE');
	// If IE, return version number.
	if (Idx > 0) {
		return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)), 10);
	}
	// If IE 11 then look for Updated user agent string.
	else if (!!navigator.userAgent.match(/Trident\/7\./)) {
		return 11;
	} else {
		return 0;
	} //It is not IE
};

export const checkiOS = () => {
	const iOS =
		!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) && !window.MSStream;

	return iOS;
};

export const getPublicUrl = (url) => {
	return process.env.REACT_APP_EVENTS_API === 'scorm' ? '.' + url : url;
};

export const getCookie = (cname) => {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return false;
};

/**
 * function que se encarga de limitar el numero de caracteres durante el renderizado de ciertos textos
 */

export const setEllipsisText = (text, limitCharacter) => {
	if (text) {
		if (text.length > limitCharacter) {
			return text.substring(0, limitCharacter) + ' ...';
		} else {
			return text;
		}
	}
};

/**
 * function que se encarga de checkear el ancho de la ventana del navegador según nuestras mediaqueries
 */

export const checkWindowWidth = () => {
	if (window.innerWidth < 480) {
		return 'mobile';
	} else if (window.innerWidth >= 480 && window.innerWidth < 768) {
		return 'mobileLandscape';
	} else if (window.innerWidth >= 768 && window.innerWidth < 992) {
		return 'tabletPortrait';
	} else if (window.innerWidth >= 992 && window.innerWidth < 1220) {
		return 'tabletLandscape';
	} else if (window.innerWidth >= 1220) {
		return 'desktop';
	}
	return 'desktop';
};

/**
 * function de convertir la primera letra de una cadena de texto a mayúscula.
 */
export function convertStringCapitalize(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}

/**
 * function que se encarga de cambiar de forma aleatorio los elementos de array.
 */
export function toShufle(array) {
	const newArray = array.slice();

	let currentIndex = newArray.length,
		temporaryValue,
		randomIndex;
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		// Math.floor (redondea al menor entero)
		// Math.random () Devuelve un numero aleatorio dentro de un rango entre [0,1)
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = newArray[currentIndex];
		newArray[currentIndex] = newArray[randomIndex];
		newArray[randomIndex] = temporaryValue;
	}
	return newArray;
}

/**
 * function que se encarga de cambiar logs types String por number de tipo decimal.
 */
export function parseTypeNumeric(string) {
	if (isNaN(string) || string === '') return undefined;
	return parseFloat(string);
}

// Función para el formateado de fechas en formato Dias/Mes/Año
// primer parámetro fecha en formato string
// segundo parámetro type: full o null
// tercer parámetro lenguage si no se pasa pondrá el español
export function formatDate(date, type, lang) {
	// si recibe el parametro type a "full" devuelve la fecha con hora, minutos y segundos
	const dateCreated = new Date(parseInt(date, 10));
	let userLang = '';
	let dateFormat = {}; //creación de objeto literal con todas sus propiedades
	if (type === 'full') {
		dateFormat = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		};
	} else {
		dateFormat = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
		};
	}
	if (!lang) {
		userLang = 'es-ES';
	} else {
		//si tenemos un lenguaje pasado por parámetro nos aseguramos de que tiene el formato correcto
		userLang = lang.replace('_', '-');
	}

	// const dateFormatZone = dateCreated.toLocaleString('es-ES', dateFormat);
	const dateFormatZone = dateCreated.toLocaleString(userLang, dateFormat);
	return dateFormatZone;
}

// Función que al pasar como argumento un valor en milisegundos te lo devuelve en semanas, dias, horas, minutos, segundos y milisegundos
export function millisecondsToReadableTime(time) {
	let milliseconds = parseInt(time, 10);
	let seconds = Math.floor(milliseconds / 1000);
	milliseconds = milliseconds % 1000;
	let minutes = Math.floor(seconds / 60);
	seconds = seconds % 60;
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	let days = Math.floor(hours / 24);
	hours = hours % 24;
	let weeks = Math.floor(days / 7);
	days = days % 24;

	const timeArray = [
		{label: 'sem', value: weeks},
		{label: 'd', value: days},
		{label: 'h', value: hours},
		{label: 'm', value: minutes},
		{label: 's', value: seconds},
		// {label: "ms", value: milliseconds}
	];

	let cleanedTimeArray = [];
	for (let timeItem of timeArray) {
		if (timeItem.value > 0) {
			cleanedTimeArray.push(`${timeItem.value}${timeItem.label}`);
		} else {
			continue;
		}
	}

	return cleanedTimeArray.toString().replace(/,/g, ' ');
}

export function getQueryParams(qs) {
	if (!qs) {
		qs = document.location.search;
	}
	qs = qs.split('+').join(' ');

	var params = {},
		tokens,
		re = /[?&]?([^=]+)=([^&]*)/g;

	while ((tokens = re.exec(qs))) {
		params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
	}

	return params;
}

/**
 * function que se encarga de convertir unidades de almacenamiento en bytes a Kbs , MB ...
 */

export function bytesToSize(bytes) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/**
 * function que se encarga de copiar un string en el portapapeles a traves de un elemento invisible
 */
export function copyToClipboard(text) {
	const el = document.createElement('textarea');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	el.value = text;
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
}

/**
 * function que se encarga de devolver un parámetro específico de una url
 */

export function getParamsURL(urlString = window.location.href, paramString) {
	const url = new URL(urlString);
	return url.searchParams.get(paramString);
}

export function getRgbaString(rgba) {
	if (!rgba) return;
	if (typeof rgba === 'string') return rgba;
	const {r, g, b, a} = rgba;
	return `rgba(${r},${g},${b},${a})`;
}

export function rgbaStringToObject(rgbaString) {
	if (!rgbaString) return;
	if (typeof rgbaString === 'object') return rgbaString;
	if (rgbaString.startsWith('rgba(')) {
		const [r, g, b, a] = rgbaString.replace('rgba(', '').replace(')', '').split(',');
		return {r, g, b, a};
	}

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(rgbaString.trim());

	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
				a: 1,
		  }
		: {};
}

export function getColorObject(input, defaultAlpha = 1, cssVar) {
	if (!input) return false;

	let color;

	if (typeof input === 'string') {
		color = tinycolor(input);
	} else if (typeof input === 'object') {
		if ('r' in input && 'g' in input && 'b' in input) {
			color = tinycolor({r: input.r, g: input.g, b: input.b, a: input.a});
		} else if ('h' in input && 's' in input && 'l' in input) {
			color = tinycolor({h: input.h, s: input.s, l: input.l});
		} else if ('h' in input && 's' in input && 'v' in input) {
			color = tinycolor({h: input.h, s: input.s, v: input.v});
		} else {
			console.error('[getColorObject]El formato de entrada no es válido', input);
			return false;
		}
	} else {
		console.error('[getColorObject]El formato de entrada no es válido', input);
		return false;
	}

	if (!color.isValid()) {
		console.error('[getColorObject]El color no es válido', input);
		return false;
	}

	if (color.getAlpha() === 1) {
		color.setAlpha(defaultAlpha);
	}

	const hsl = color.toHsl();
	const hex = color.toHexString();
	const rgb = color.toRgb();
	const hsv = color.toHsv();

	return {
		hsl: hsl,
		hex: hex,
		rgb: rgb,
		hsv: hsv,
		oldHue: hsl.h,
		source: typeof input === 'string' ? 'hex' : color.getFormat(),
		cssVar: cssVar,
		tinycolor: color,
	};
}

export function storageAvailable(type) {
	try {
		const storage = window[type],
			x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		Logger.captureException(e);
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage.length !== 0
		);
	}
}

export function getYoutubeId(url) {
	// const regex = /(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\\?v=|\\?vi=)([^#\&\?]*).*/;
	// const match = url.match(regex);

	// if (match && match[2].length === 11) {
	// 	return match[2];
	// }

	// return null;
	try {
		// Crear un objeto URL para analizar la URL
		const parsedUrl = new URL(url);

		// Reglas para diferentes formatos
		if (parsedUrl.hostname === 'youtu.be') {
			// Formato corto
			return parsedUrl.pathname.substring(1); // /VIDEO_ID
		} else if (
			parsedUrl.hostname === 'www.youtube.com' ||
			parsedUrl.hostname === 'youtube.com' ||
			parsedUrl.hostname.startsWith('m.youtube')
		) {
			if (parsedUrl.pathname.startsWith('/watch')) {
				// Formato estándar
				return parsedUrl.searchParams.get('v');
			} else if (parsedUrl.pathname.startsWith('/embed/')) {
				// Formato embebido
				return parsedUrl.pathname.split('/')[2]; // /embed/VIDEO_ID
			} else if (parsedUrl.pathname.startsWith('/shorts/')) {
				// Formato Shorts
				return parsedUrl.pathname.split('/')[2]; // /shorts/VIDEO_ID
			}
		}

		// Si no coincide con ninguna regla conocida
		return null;
	} catch (error) {
		// Manejo de errores (URL inválida, etc.)
		console.error('Error al procesar la URL:', error);
		return null;
	}
}
