//Importamos los ladrillos

//Contenedor principal a una columna
import MainContainerBricks from './MainContainerBricks';

// Ladrillo de portada
import Cover from './Cover/Cover';
import BudgetCover from './Cover/BudgetCover';
import ProposalCover from './Cover/ProposalCover';

// Ladrillos Interactivos
import ShowInfoTabs from './Interactive/ShowInfoTabs';
import ShowInfoHexagons from './Interactive/ShowInfoHexagons';
import ShowInfoOneImage from './Interactive/ShowInfoOneImage';
import ShowInfoTwoImages from './Interactive/ShowInfoTwoImages';
import ShowInfoThreeImages from './Interactive/ShowInfoThreeImages';
import ShowHotSpotsMediumSize from './Interactive/ShowHotSpotsMediumSize';
import ShowHotSpots from './Interactive/ShowHotSpots';
import ShowHotSpotsPopUp from './Interactive/ShowHotSpotsPopUp';
import ShowHotSpotsPopUpImageCenter from './Interactive/ShowHotSpotsPopUpImageCenter';
import ShowInfoDidYouKnow from './Interactive/ShowInfoDidYouKnow';
import ShowInfoLabels from './Interactive/ShowInfoLabels';
import ContactForm from './Interactive/ContactForm';
import NewContactForm from './Interactive/NewContactForm';
import NewPersonalizableContactForm from './Interactive/NewPersonalizableContactForm';
import MapIframe from './Interactive/MapIframe';
import PdfFile from './Interactive/PdfFile';
import ExternalDownloadButton from './Interactive/ExternalDownloadButton';
import ExternalLinkButton from './Interactive/ExternalLinkButton';
import CountDownTimer from './Interactive/CountDownTimer';
import HTMLCode from './Interactive/HTMLCode';
import AccordionSmall from './Interactive/AccordionSmall';
import AccordionBig from './Interactive/AccordionBig';

// Ladrillos de evaluación
import TestBasic from './Evaluate/TestBasic';
import TestBasicWithImage from './Evaluate/TestBasicWithImage';
import TestExam from './Evaluate/TestExamV2';
import DiscoverImage from './Evaluate/DiscoverImage';
import ClassifyGame from './Evaluate/ClassifyGame';
import ClassifyGameImage from './Evaluate/ClassifyGameImage';

// Ladrillos de obtener información
import TestPersonality from './GetInfo/TestPersonality';
import Survey from './GetInfo/Survey';
import TestPersonalityWithImages from './GetInfo/TestPersonalityWithImages';
import ClassifySurvey from './GetInfo/ClassifySurvey';
import ClassifySurveyImage from './GetInfo/ClassifySurveyImage';

// Ladrillos de concurso
import BasicContest from './Contest/BasicContest';
import PersonalizableContest from './Contest/PersonalizableContest';

//Ladrillos de texto
//Párrafo
import Paragraph from './Text/Paragraph/Paragraph';
import ParagraphV2 from './Text/Paragraph/ParagraphV2';
import ParagraphWithTitleIzq from './Text/Paragraph/ParagraphWithTitleIzq';
import ParagraphTwoColumnsTitle from './Text/Paragraph/ParagraphTwoColumnsTitle';
import ParagraphThreeColumnsTitle from './Text/Paragraph/ParagraphThreeColumnsTitle';
import ParagraphWithCapital from './Text/Paragraph/ParagraphWithCapital';
import ParagraphInterview from './Text/Paragraph/ParagraphInterview';

//Encabezado
import HeadingBig from './Text/Heading/HeadingBig';
import HeadingMiddle from './Text/Heading/HeadingMiddle';
import HeadingMiddleCenter from './Text/Heading/HeadingMiddleCenter';
import HeadingBlackBox from './Text/Heading/HeadingBlackBox';
import HeadingFullWidthBox from './Text/Heading/HeadingFullWidthBox';
import HeadingSubheading from './Text/Heading/HeadingSubheading';
import HeadingSubheadingBox from './Text/Heading/HeadingSubheadingBox';
import HeadingBeforeHeading from './Text/Heading/HeadingBeforeHeading';
import HeadingBeforeHeadingBox from './Text/Heading/HeadingBeforeHeadingBox';
import HeadingExcerpt from './Text/Heading/HeadingExcerpt';
import HeadingWithNumber from './Text/Heading/HeadingWithNumber';

//Destacado
import ImportantTextCenterBox from './Text/Quote/ImportantTextCenterBox';
import ImportantTextLeft from './Text/Quote/ImportantTextLeft';
import ImportantTextRight from './Text/Quote/ImportantTextRight';
import ImportantTextLateralDetail from './Text/Quote/ImportantTextLateralDetail';
import ImportantTextCenterQuotes from './Text/Quote/ImportantTextCenterQuotes';
import ImportantTextCenterQuotesBigSize from './Text/Quote/ImportantTextCenterQuotesBigSize';
import ImportantTextCenterQuotesBorderBigSize from './Text/Quote/ImportantTextCenterQuotesBorderBigSize';
import ImportantTextCenterQuotesBorder from './Text/Quote/ImportantTextCenterQuotesBorder';
import ImportantTextWithPhoto from './Text/Quote/ImportantTextWithPhoto';
import ImportantTextWithPhotoBox from './Text/Quote/ImportantTextWithPhotoBox';
import ImportantTextWithPhotoOutsideBox from './Text/Quote/ImportantTextWithPhotoOutsideBox';
import ImportantTextBorderAuthor from './Text/Quote/ImportantTextBorderAuthor';
import ImportantTextColorBox from './Text/Quote/ImportantTextColorBox';
import ImportantTextMsgBox from './Text/Quote/ImportantTextMsgBox';
import ImportantTextBigSize from './Text/Quote/ImportantTextBigSize';

//Lista
import ListSmallNumbers from './Text/List/ListSmallNumbers';
import ListBigNumbers from './Text/List/ListBigNumbers';
import ListOrderedWithBox from './Text/List/ListOrderedWithBox';
import ListWithSquarePoints from './Text/List/ListWithSquarePoints';
import ListOrderedWithImg from './Text/List/ListOrderedWithImg';
import ListOrderedWithImgLine from './Text/List/ListOrderedWithImgLine';
import ListOrderedWithImgAlternate from './Text/List/ListOrderedWithImgAlternate';

//Ladrillos de imagen
//Imagen
import Image from './Image/Image/Image';
import VerticalImage from './Image/Image/VerticalImage';
import ImageWithLink from './Image/Image/ImageWithLink';
import ImagesWithLink from './Image/Image/ImagesWithLink';
import ImageFullWidth from './Image/Image/ImageFullWidth';
import TwoImagesVertical from './Image/Image/TwoImagesVertical';
import TwoImagesSquare from './Image/Image/TwoImagesSquare';
import TwoImagesVerticalNoMargin from './Image/Image/TwoImagesVerticalNoMargin';
import TwoImagesNoMargin from './Image/Image/TwoImagesNoMargin';
import TwoImagesSquareNoMargin from './Image/Image/TwoImagesSquareNoMargin';
import TwoImagesCompositionZIndexA from './Image/Image/TwoImagesCompositionZIndexA';
import TwoImagesCompositionZIndexB from './Image/Image/TwoImagesCompositionZIndexB';
import ThreeImagesVertical from './Image/Image/ThreeImagesVertical';
import ThreeImagesSquare from './Image/Image/ThreeImagesSquare';
import ThreeImagesVerticalNoMargin from './Image/Image/ThreeImagesVerticalNoMargin';
import ThreeImagesSquareNoMargin from './Image/Image/ThreeImagesSquareNoMargin';
import FourImagesMargin from './Image/Image/FourImagesMargin';
import FourImagesNoMargin from './Image/Image/FourImagesNoMargin';
import SixImagesSquare from './Image/Image/SixImagesSquare';
import SixImagesSquareNoMargin from './Image/Image/SixImagesSquareNoMargin';
import ThreeImageComposition from './Image/Image/ThreeImageComposition';
import FourImageComposition from './Image/Image/FourImageComposition';
import FourImageCompositionZIndex from './Image/Image/FourImageCompositionZIndex';
import ThreeImageCompositionZIndex from './Image/Image/ThreeImageCompositionZIndex';
import ThreeImageCompositionColDiffMarginTop from './Image/Image/ThreeImageCompositionColDiffMarginTop';
import ThreeImageCompositionColDiffMTopWidth from './Image/Image/ThreeImageCompositionColDiffMTopWidth';
import FiveImageComposition from './Image/Image/FiveImageComposition';
import SixImageComposition from './Image/Image/SixImageComposition';
import Banner from './Image/Image/Banner';

//Imagen con texto
import ImageTitleTextBox from './Image/ImageWithText/ImageTitleTextBox';
import ImageTitleText from './Image/ImageWithText/ImageTitleText';
import TwoImagesSquareTitleText from './Image/ImageWithText/TwoImagesSquareTitleText';
import TwoImagesTitleTextNoMargin from './Image/ImageWithText/TwoImagesTitleTextNoMargin';
import ThreeImagesSquareTitleText from './Image/ImageWithText/ThreeImagesSquareTitleText';
import ThreeImagesFullWidthTitleText from './Image/ImageWithText/ThreeImagesFullWidthTitleText';
import ThreeImagesCompositionWithText from './Image/ImageWithText/ThreeImagesCompositionWithText';
import TwoImagesCompositionWithText from './Image/ImageWithText/TwoImagesCompositionWithText';
import ImageFullWidthWithText from './Image/ImageWithText/ImageFullWidthWithText';
import ThreeImagesCompositionOneText from './Image/ImageWithText/ThreeImagesCompositionOneText';
import ThreeImagesSquareTitleTextBehind from './Image/ImageWithText/ThreeImagesSquareTitleTextBehind';

//Carrousel
import CarouselWithBottomCaption from './Image/Carousel/CarouselWithBottomCaption';
import CarouselFullWidthLeftCaption from './Image/Carousel/CarouselFullWidthLeftCaption';
import CarouselFullWidthLeftCaptionOut from './Image/Carousel/CarouselFullWidthLeftCaptionOut';
import CarouselFullWidth from './Image/Carousel/CarouselFullWidth';
import CarouselFullWidthCaption from './Image/Carousel/CarouselFullWidthCaption';
import CarouselWithBottomRightCaption from './Image/Carousel/CarouselWithBottomRightCaption';
import CarouselBlurEffect from './Image/Carousel/CarouselBlurEffect';
import CarouselBlurEffectVertical from './Image/Carousel/CarouselBlurEffectVertical';

//Galería
import GalleryInteractive from './Image/Gallery/GalleryInteractive';
import GalleryVerticalWithText from './Image/Gallery/GalleryVerticalWithText';
import GalleryHorizontal from './Image/Gallery/GalleryHorizontal';

// MULTIMEDIA
//Vídeo
import VideoCentered from './Video/VideoCentered';
import VerticalVideo from './Video/VerticalVideo';

//Audio
import Audio from './Audio/Audio';
import AudioAutoPlay from './Audio/AudioAutoPlay';
import AudioStop from './Audio/AudioStop';
import Podcast from './Audio/Podcast';

//Transición
import Background from './Transition/Background';
import ScrollPause from './Transition/ScrollPause';
import PlayScroll from './Transition/PlayScroll';
import StopScroll from './Transition/StopScroll';

// SEPARADORES
import SeparatorLineWithImg from './Separators/SeparatorLineWithImg';
import SeparatorBall from './Separators/SeparatorBall';
import SeparatorLine from './Separators/SeparatorLine';

// ESPACIADORES
import SpacerQuarterScreen from './Spacers/SpacerQuarterScreen';
import SpacerHalfScreen from './Spacers/SpacerHalfScreen';
import SpacerFullScreen from './Spacers/SpacerFullScreen';
import SpacerCustom from './Spacers/SpacerCustom';

//Fin import ladrillos - No modificar esta cadena
import BarDiagram from './visualData/BarDiagram';
import LineDiagram from './visualData/LineDiagram';
import AreaDiagram from './visualData/AreaDiagram';
import PieDiagram from './visualData/PieDiagram';
//Creamos un array con las asociaciones entre texto y componente
export const BRICKS = {
	MainContainerBricks,
	Cover,
	BudgetCover,
	ProposalCover,
	ShowInfoTabs,
	ShowInfoHexagons,
	ShowInfoOneImage,
	ShowInfoTwoImages,
	ShowInfoThreeImages,
	ShowHotSpots,
	ShowHotSpotsMediumSize,
	ShowHotSpotsPopUp,
	ShowHotSpotsPopUpImageCenter,
	ShowInfoDidYouKnow,
	ShowInfoLabels,
	ContactForm,
	NewContactForm,
	NewPersonalizableContactForm,
	MapIframe,
	BasicContest,
	PersonalizableContest,
	PdfFile,
	ExternalDownloadButton,
	ExternalLinkButton,
	CountDownTimer,
	HTMLCode,
	TestBasic,
	TestBasicWithImage,
	TestExam,
	TestPersonality,
	TestPersonalityWithImages,
	DiscoverImage,
	ClassifyGame,
	ClassifyGameImage,
	ClassifySurvey,
	ClassifySurveyImage,
	Survey,
	Paragraph,
	ParagraphV2,
	ParagraphWithTitleIzq,
	ParagraphTwoColumnsTitle,
	ParagraphThreeColumnsTitle,
	ParagraphWithCapital,
	ParagraphInterview,
	HeadingBig,
	HeadingMiddle,
	HeadingMiddleCenter,
	HeadingBlackBox,
	HeadingFullWidthBox,
	HeadingSubheading,
	HeadingSubheadingBox,
	HeadingBeforeHeading,
	HeadingBeforeHeadingBox,
	HeadingExcerpt,
	HeadingWithNumber,
	ImportantTextCenterBox,
	ImportantTextLeft,
	ImportantTextRight,
	ImportantTextLateralDetail,
	ImportantTextCenterQuotes,
	ImportantTextCenterQuotesBigSize,
	ImportantTextCenterQuotesBorderBigSize,
	ImportantTextCenterQuotesBorder,
	ImportantTextWithPhoto,
	ImportantTextWithPhotoBox,
	ImportantTextWithPhotoOutsideBox,
	ImportantTextBorderAuthor,
	ImportantTextColorBox,
	ImportantTextMsgBox,
	ImportantTextBigSize,
	Image,
	VerticalImage,
	ImageWithLink,
	ImagesWithLink,
	ImageFullWidth,
	TwoImagesVertical,
	TwoImagesSquare,
	TwoImagesVerticalNoMargin,
	TwoImagesNoMargin,
	TwoImagesSquareNoMargin,
	TwoImagesCompositionZIndexA,
	TwoImagesCompositionZIndexB,
	ThreeImagesVertical,
	ThreeImagesSquare,
	ThreeImagesVerticalNoMargin,
	ThreeImagesSquareNoMargin,
	FourImagesMargin,
	FourImagesNoMargin,
	SixImagesSquare,
	SixImagesSquareNoMargin,
	ThreeImageComposition,
	ThreeImageCompositionColDiffMarginTop,
	ThreeImageCompositionColDiffMTopWidth,
	FourImageComposition,
	FourImageCompositionZIndex,
	ThreeImageCompositionZIndex,
	FiveImageComposition,
	SixImageComposition,
	ImageTitleTextBox,
	ImageTitleText,
	TwoImagesSquareTitleText,
	TwoImagesTitleTextNoMargin,
	ThreeImagesSquareTitleText,
	ThreeImagesFullWidthTitleText,
	ThreeImagesCompositionWithText,
	TwoImagesCompositionWithText,
	ThreeImagesCompositionOneText,
	ImageFullWidthWithText,
	ThreeImagesSquareTitleTextBehind,
	CarouselWithBottomCaption,
	CarouselWithBottomRightCaption,
	CarouselFullWidthLeftCaption,
	CarouselFullWidthLeftCaptionOut,
	CarouselFullWidth,
	CarouselFullWidthCaption,
	CarouselBlurEffect,
	CarouselBlurEffectVertical,
	GalleryInteractive,
	GalleryVerticalWithText,
	GalleryHorizontal,
	ListSmallNumbers,
	ListBigNumbers,
	ListOrderedWithBox,
	ListWithSquarePoints,
	ListOrderedWithImg,
	ListOrderedWithImgLine,
	ListOrderedWithImgAlternate,
	VideoCentered,
	VerticalVideo,
	Audio,
	AudioAutoPlay,
	AudioStop,
	Podcast,
	Background,
	ScrollPause,
	PlayScroll,
	StopScroll,
	SeparatorLineWithImg,
	SeparatorBall,
	SeparatorLine,
	SpacerQuarterScreen,
	SpacerHalfScreen,
	SpacerFullScreen,
	SpacerCustom,
	BarDiagram,
	LineDiagram,
	AreaDiagram,
	PieDiagram,
	Banner,
	AccordionSmall,
	AccordionBig,
};

export const getBrickName = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getName();
	return '';
};
export const getBrickHelpText = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getHelpText();
	return '';
};

export const getBrickIcon = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getIcon();
	return '';
};

export const getBrickImage = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getImage();
	return '';
};

export const getBrickImage2 = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getImage2();
	return '';
};

export const getBrickDefaultData = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getDefaultData();
	return '';
};

export const getBrickDefaultConfig = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getDefaultConfig();
	return '';
};

export const getBrickDefaultStyles = (brickType) => {
	if (BRICKS[brickType]) return BRICKS[brickType].getDefaultStyles();
	return '';
};
