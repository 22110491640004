import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './styles.scss';

const Input = React.forwardRef((props, ref) => {
	const {
		name,
		value,
		type,
		onChange,
		onBlur,
		onKeyUp,
		onKeyDown,
		className,
		placeholder,
		step,
		min,
		max,
		id,
		errorText,
		theme,
		checked,
		defaultChecked,
		label,
		required,
		tabIndex,
		disabled,
		dataCypress,
		autoFocus,
	} = props;

	const [showPassword, setShowPassword] = React.useState(false);

	const classes = classNames({
		input: true,
		[className]: true,
		[`${theme}`]: true,
		error: errorText !== '',
	});

	return (
		<div className={classes}>
			{label && label !== '' && (
				<label htmlFor={name}>
					{label} {required && <sup>*</sup>}
				</label>
			)}
			<div className="input-wrapper">
				<input
					{...{
						name,
						value,
						type,
						onChange,
						onBlur,
						onKeyUp,
						onKeyDown,
						placeholder,
						step,
						min,
						max,
						id,
						theme,
						checked,
						defaultChecked,
						label,
						required,
						tabIndex,
						disabled,
						autoFocus,
					}}
					type={type === 'password' && showPassword ? 'text' : type}
					data-cypress={dataCypress}
					ref={ref}
				/>
				{type === 'password' && (
					<button
						type="button"
						className="password-toggle"
						onClick={() => setShowPassword(!showPassword)}
						aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
					>
						{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
					</button>
				)}
			</div>
			{errorText !== '' && <span className="input__error">{errorText}</span>}
		</div>
	);
});

const {string, func, bool} = PropTypes;

Input.propTypes = {
	name: string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: string.isRequired,
	onChange: func,
	onChangeFunction: func,
	onBlur: func,
	onKeyUp: func,
	onKeyDown: func,
	className: string,
	placeholder: string,
	theme: PropTypes.oneOf([
		'generic',
		'platform-input',
		'checkbox',
		'disabled',
		'admin',
		'user',
		'super-admin',
		'shared',
	]),
	errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	checked: bool,
	defaultChecked: bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

Input.defaultProps = {
	name: '',
	type: 'text',
	className: '',
	placeholder: '',
	theme: 'generic',
	errorText: '',
	disabled: false,
};

export default Input;
