import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class PixelProperty extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = editionBrickStruct.defaultValue || 10;

		return defaultValue;
	};

	handleChange = (event) => {
		this.props.setMainField(event.target.value);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;
		const label = editionBrickStruct.label;

		const value = this.props.getMainField() === undefined ? 10 : this.props.getMainField();

		return (
			<StylesOptionsWrapper>
				<label className="item-title pixel-property-item">{label}</label>
				<div>
					<input
						className="pixel-property-input"
						type="number"
						name="value"
						value={value}
						step="5"
						placeholder={t`Aumenta o disminuye el valor`}
						onChange={this.handleChange}
					/>
					{'px'}
				</div>
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(PixelProperty);
