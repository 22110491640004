import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import Badge from '@mui/material/Badge';

const Icon = ({step}) => {
	if (step >= 0) {
		return <TextIncreaseIcon />;
	}

	return <TextDecreaseIcon />;
};

const IncreaseFontButton = ({step = 0.1}) => {
	const editor = useSlate();

	const {getMark, setMark} = editor.helpers;

	const emFontSize = getMark(editor, 'emFontSize') || 1;
	const isIncreasing = step >= 0;
	const isActive = isIncreasing ? emFontSize > 1 : emFontSize < 1;

	const nSteps = Math.round((emFontSize - 1) / step);
	const showBadge = nSteps > 0;

	return (
		<>
			<ToggleButton
				format={'emFontSize'}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					setMark(editor, 'emFontSize', emFontSize + step);
				}}
				selected={isActive}
				disabled={emFontSize + step <= 0}
			>
				<Badge
					badgeContent={nSteps}
					color="primary"
					invisible={!showBadge}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					sx={{
						'& .MuiBadge-badge': {
							fontSize: '0.6rem',
							minWidth: 15,
							height: 15,
							padding: 0,
						},
					}}
				>
					<Icon
						step={step}
						// sx={{
						// 	color: isActive ? color.hex : 'inherit',
						// }}
					/>
				</Badge>
			</ToggleButton>
		</>
	);
};

export default IncreaseFontButton;
