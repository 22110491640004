import gql from 'graphql-tag';
import {Permissions} from '../fragments';

export const ENTITY_QUERY = gql`
	query CurrentEntity($preview: Boolean) {
		currentEntity(preview: $preview) {
			id
			type
			subtype
			slug
			name
			description
			image
			data
			config
			appCustomizations
			sections {
				name
				slug
				config
				sections {
					name
					slug
					config
					sections {
						name
						slug
						config
					}
				}
			}
			productData {
				userPermissions {
					showLogoInPublicationPillType
					requireLogin
					gamesRequireLogin
					isContentAccessControlled
					homeExists
					showSearchLink
					showSearchBarInHome
					showGamification
					showNotificationsBell
					profile_editAvatar
					profile_editName
					profile_editEmail
					profile_editPassword
					profile_editNotifications
					profile_learningTab
					profile_goalsTab
					showWhoIsWho
					filterOnSections
					allowInitialPopups
					showPublicationsTab
					publishTextKey
					shuffleLinksHomeSection
					linksHomeSectionNoText
					showPoweredByOnPills
				}
				adminPermissions {
					...Permissions
				}
				productDefinition {
					logo
					pill_status_label
					pill_scope_access_label
					pill_publish_label_and_tab
					pill_share_link_label_and_tab
					pill_notifications_label_and_tab
					users_active
				}
			}
			langs {
				adminLang
				userLang
				preloadLangs
			}
			group
		}
	}
	${Permissions}
`;

export const SET_ENTITY_DATA = gql`
	mutation setEntityData($id: String!, $data: ScalarGeneric) {
		setEntityData(id: $id, data: $data) {
			id
			type
			subtype
			slug
			name
			description
			image
			data
			config
			sections {
				name
				slug
			}
			productData {
				userPermissions {
					showLogoInPublicationPillType
					requireLogin
					gamesRequireLogin
					isContentAccessControlled
					homeExists
					showSearchLink
					showSearchBarInHome
					showGamification
					showNotificationsBell
					profile_editAvatar
					profile_editName
					profile_editEmail
					profile_editPassword
					profile_editNotifications
					profile_learningTab
					profile_goalsTab
					showWhoIsWho
					filterOnSections
					allowInitialPopups
					showPublicationsTab
					publishTextKey
				}
				adminPermissions {
					...Permissions
				}
				productDefinition {
					logo
					pill_status_label
					pill_scope_access_label
					pill_publish_label_and_tab
					pill_share_link_label_and_tab
					pill_notifications_label_and_tab
					users_active
				}
			}
			langs {
				adminLang
				userLang
			}
			group
		}
	}
	${Permissions}
`;

export const ADMIN_SECTIONS = gql`
	fragment AdminSectionFields on SectionType {
		name
		slug
		type
		shortName
		description
		isSuperSection
		icon
	}
	query AdminSections {
		adminSections {
			...AdminSectionFields
			sections {
				...AdminSectionFields
				sections {
					...AdminSectionFields
				}
			}
		}
	}
`;

const userSectionsFragment = gql`
	fragment UserSectionFields on SectionType {
		name
		slug
		type
		shortName
		description
		userIntroText
		icon
		isSuperSection
		color
		config
		access {
			rule
		}
		hasPills
	}
`;

export const USER_SECTIONS_ENTITY_QUERY = gql`
	${userSectionsFragment}
	query UserSections {
		userSections {
			...UserSectionFields
			sections {
				...UserSectionFields
				sections {
					...UserSectionFields
				}
			}
		}
	}
`;

export const USER_SECTIONS_SUMMARY_ENTITY_QUERY = gql`
	${userSectionsFragment}
	query UserSections {
		userSections {
			...UserSectionFields
			sections {
				...UserSectionFields
				sections {
					...UserSectionFields
				}
			}
		}
	}
`;

export const GET_SLUG_TYPE_QUERY = gql`
	query slugType($slug: String) {
		slugType(slug: $slug) {
			type
			section {
				...UserSectionFields
				parentSection {
					name
					slug
					type
					color
					shortName
					description
					userIntroText
					icon
					isSuperSection
				}
				sections {
					...UserSectionFields
					pills {
						id
					}
					sections {
						...UserSectionFields
						pills {
							id
						}
					}
				}
			}
			taxonomyType {
				id
				name
				singularName
				slug
				description
			}
		}
	}
	${userSectionsFragment}
`;

export const ADD_GROUP_TO_ADMIN_SCOPE = gql`
	mutation addGroupToAdminScope($adminEmail: String!, $groupId: String!) {
		addGroupToAdminScope(adminEmail: $adminEmail, groupId: $groupId) {
			admins {
				email
				scope
			}
		}
	}
`;
export const DELETE_GROUP_FROM_ADMIN_SCOPE = gql`
	mutation deleteGroupFromAdminScope($adminEmail: String!, $groupId: String!) {
		deleteGroupFromAdminScope(adminEmail: $adminEmail, groupId: $groupId) {
			admins {
				email
				scope
			}
		}
	}
`;
export const SET_ALL_PERMISSIONS_TO_ADMIN_SCOPE = gql`
	mutation setAllPermissionsToAdminScope(
		$adminEmail: String!
		$allUsers: Boolean!
		$allGroups: Boolean!
		$allSections: Boolean!
	) {
		setAllPermissionsToAdminScope(
			adminEmail: $adminEmail
			allUsers: $allUsers
			allGroups: $allGroups
			allSections: $allSections
		) {
			admins {
				email
				scope
			}
		}
	}
`;

export const ADMIN_SET_ADMIN_CONFIG = gql`
	mutation adminSetAdminConfig($adminEmail: String!, $config: ScalarGeneric!) {
		adminSetAdminConfig(adminEmail: $adminEmail, config: $config) {
			admins {
				email
				scope
			}
		}
	}
`;

export const ADD_SECTION_TO_ADMIN_SCOPE = gql`
	mutation addSectionToAdminScope($adminEmail: String!, $sectionSlug: String!) {
		addSectionToAdminScope(adminEmail: $adminEmail, sectionSlug: $sectionSlug) {
			admins {
				email
				scope
			}
		}
	}
`;
export const DELETE_SECTION_FROM_ADMIN_SCOPE = gql`
	mutation deleteSectionFromAdminScope($adminEmail: String!, $sectionSlug: String!) {
		deleteSectionFromAdminScope(adminEmail: $adminEmail, sectionSlug: $sectionSlug) {
			admins {
				email
				scope
			}
		}
	}
`;
