import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Speedometer from 'businessLogic/core/shared/Icon/Speedometer';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class FinalFeedback extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			counterScore: 0,
		};
	}
	scoreAnimateInit = () => {
		let automaticScore = setInterval(() => {
			this.setState((state, props) => {
				let currentCounterScore = state.counterScore;

				if (currentCounterScore < props.score.raw) {
					currentCounterScore++;
				} else {
					clearInterval(automaticScore);
				}
				return {
					counterScore: currentCounterScore,
				};
			});
		}, 100);
	};
	componentDidMount() {
		this.scoreAnimateInit();
	}
	render() {
		const mainStyleClass = 'test-exam-final-feedback';
		const classes = classNames({
			brick: true,
			[`${mainStyleClass}`]: true,
		});
		const {
			dataText: {beforeHeading, textButton, markCutOff},
			image,
			score: {max, min},
			isClosedTest,
		} = this.props;

		let failure = (
			<CallActionTestBrickWithBoxes
				type="toRepeat"
				textButton={textButton}
				onClick={!isClosedTest ? this.props.onClick : undefined}
				beforeHeading={beforeHeading}
				set={this.props.set}
				className={classes}
				image={image}
			>
				<div className={mainStyleClass + '__icon--feedback'}>
					<Icon name="emoticon--sad" size="normal" />
				</div>

				<div className="test-exam-brick__title">
					<p>{t`Necesitas mejorar`}</p>
				</div>
				<div className={mainStyleClass + '__subtitle--feedback'}>
					<p>{t`No has alcanzado el ${markCutOff}% de aciertos`}</p>
				</div>
			</CallActionTestBrickWithBoxes>
		);
		if (this.props.isSuccess === undefined) return null;
		if (this.props.isSuccess === false) return failure;
		return (
			<CallActionTestBrickWithBoxes
				type="toInfoFinish"
				textButton={textButton}
				onClick={this.props.onClick}
				beforeHeading={beforeHeading}
				set={this.props.set}
				className={classes}
				image={image}
			>
				<div className={mainStyleClass + '__icon--feedback'}>
					<Speedometer min={min} max={max} value={this.state.counterScore} />
				</div>

				<div className="test-exam-brick__title test-exam-brick__title--success">
					<p>{t`Enhorabuena`}</p>
				</div>
				<div className={mainStyleClass + '__subtitle--feedback'}>
					<p>
						{this.state.counterScore !== 1
							? t`Has obtenido ${this.state.counterScore} puntos`
							: t`Has obtenido ${this.state.counterScore} punto`}
					</p>
				</div>
			</CallActionTestBrickWithBoxes>
		);
	}
}
FinalFeedback.propTypes = {
	isSuccess: PropTypes.bool,
	dataText: PropTypes.object,
	score: PropTypes.object,
	onClick: PropTypes.func,
	isClosedTest: PropTypes.bool,
	set: PropTypes.func,
};
FinalFeedback.defaultProps = {
	dataText: {
		beforeHeading: '',
		textButton: t`Texto Botón`,
		markCutOff: '',
	},
	score: {
		scaled: undefined,
		raw: undefined,
	},
};
export default FinalFeedback;
