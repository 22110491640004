import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'subtitleLess',
						title: t`Antetítulo contenido visible`,
						placeholder: t`¿Sabías qué?`,
					},
					{
						type: 'TextEditor',
						fieldName: 'titleLess',
						title: t`Título contenido visible`,
						placeholder: t`Introduce el título visible`,
					},
					{
						type: 'Image',
						fieldName: 'imageObjLess',
						defaultImage: '/uploads/default-images/csDefault2Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
					},
					{
						type: 'TextEditor',
						fieldName: 'textLess',
						title: t`Texto contenido visible`,
						placeholder: t`Introduce el texto previsualizado`,
					},
					{
						type: 'Image',
						fieldName: 'imageObjMore',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
					},
					{
						type: 'TextEditor',
						fieldName: 'textMore',
						title: t`Texto contenido oculto o`,
						placeholder: t`Texto del contenido oculto`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},
					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
				],
			},
		],
	},
];
export default editionFields;
