import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'firstImage',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
					},
					{
						type: 'Repeater',
						min: 2,
						max: 2,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault2Opt.svg',
								defaultCredit: t`Crédito de ejemplo`,
							},
							{
								type: 'TextEditor',
								fieldName: 'beforeTitle',
								title: t`Antetítulo de la imagen`,
								placeholder: t`Introduce el antetítulo de la imagen`,
							},
							{
								type: 'TextEditor',
								fieldName: 'title',
								title: t`Título de la imagen`,
								placeholder: t`Introduce el título de la imagen`,
							},
							{
								type: 'TextEditor',
								fieldName: 'text',
								title: t`Texto de la imagen`,
								placeholder: t`Introduce el texto de la imagen`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
