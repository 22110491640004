import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'File',
						fieldName: 'file',
						defaultFile: '/myb-sample.pdf',
					},
					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de descarga`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						label: t`Color de fondo de botón`,
						fieldName: ['bgColor'],
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginTop'],
						label: t`Margen superior`,
					},
					{
						type: 'PixelProperty',
						fieldName: ['containerStyle', 'marginBottom'],
						label: t`Margen inferior`,
					},
				],
			},
		],
	},
];
export default editionFields;
