import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'polyfill.js';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {CaptureConsole} from '@sentry/integrations';
import Logger from 'businessLogic/services/Logger';

//En los entornos de staging y producción activamos Sentry para el log de errores
// if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_EVENTS_API !== 'scorm') {
if (process.env.REACT_APP_EVENTS_API !== 'scorm') {
	Sentry.init({
		dsn: 'https://e4af70d77b164d8fa8ca8cc48c01ff01@o486865.ingest.sentry.io/6170584',
		integrations: [
			new Integrations.BrowserTracing(),
			new CaptureConsole({
				levels: ['error', 'warn', 'log'], // Define los niveles que quieres capturar
			}),
		],
		ignoreErrors: [
			'TypeError: Failed to fetch',
			'TypeError: NetworkError when attempting to fetch resource.',
			'TypeError: Cancelled',
			'TypeError: cancelled',
			'TypeError: cancelado',
			'Non-Error promise rejection captured with value: Object Not Found Matching Id',
		],
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control d
		tracesSampleRate: 0.1,
		environment: process.env.NODE_ENV,
		release: `cs-app@${process.env.REACT_APP_RELEASE || 'dev'}`,
	});
}

ReactDOM.render(
	<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'app')}>
		<App />
	</Logger.ErrorBoundary>,
	document.getElementById('root'),
);

if (process.env.REACT_APP_EVENTS_API !== 'scorm') {
	serviceWorkerRegistration.register();
	// serviceWorkerRegistration.unregister();
}
