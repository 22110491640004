import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import {imageUrl} from 'businessLogic/helpers/images';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import classNames from 'classnames';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class CarouselBlurEffectVertical extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Carrusel vertical efecto desenfoque`;
	static brickIcon = 'carousel';
	static brickImage = brickImage;

	static brickDefaultData = () => ({
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault5Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault6Opt.svg',
					credit: '',
				},
			},
		],
	});

	constructor(props) {
		super(props);
		this.state = {currentIndex: 0};
	}

	handleNextClick = () => {
		this.setState((prevState) => ({
			currentIndex: (prevState.currentIndex + 1) % this.props.brick.data.images.length,
		}));
	};

	handlePreviousClick = () => {
		this.setState((prevState) => ({
			currentIndex:
				prevState.currentIndex === 0
					? this.props.brick.data.images.length - 1
					: prevState.currentIndex - 1,
		}));
	};

	/*
	CONSTRUIR LA GALERIA CON ESTE EJEMPLO 
	https://www.sitepoint.com/building-3d-rotating-carousel-css-javascript/
	*/

	render() {
		const data = this.props.brick.data;
		const config = this.props.brick.config || {};
		const styles = this.props.brick.styles || {};
		const images = data.images || [];

		const classes = classNames({
			'brick': true,
			'carousel-blur-effect-vertical': true,
		});

		const showMainImageComplete = config.showMainImageComplete || false;
		const showSecondaryComplete = config.showSecondaryComplete || false;
		const disableInfiniteLoop = config.disableInfiniteLoop || false;

		const mainImageStyles = showMainImageComplete
			? {backgroundSize: 'contain', backgroundRepeat: 'no-repeat', boxShadow: 'none'}
			: {};
		const secondaryImageStyles = showSecondaryComplete
			? {backgroundSize: 'contain', backgroundRepeat: 'no-repeat', boxShadow: 'none'}
			: {};

		const showPrevImage = disableInfiniteLoop ? this.state.currentIndex > 0 : true;
		const showNextImage = disableInfiniteLoop ? this.state.currentIndex < images.length - 1 : true;

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div
						className="img-secondary"
						style={{
							...secondaryImageStyles,
							visibility: showPrevImage ? 'visible' : 'hidden',
							backgroundImage: showPrevImage
								? `url('${imageUrl(
										images[
											this.state.currentIndex === 0
												? images.length - 1
												: this.state.currentIndex - 1
										].imageObj.imageSrc,
								  )}')`
								: 'none',
						}}
						onClick={this.handlePreviousClick}
					/>

					<div className="img-main">
						<NewImageClickable
							imgObj={data.images[this.state.currentIndex].imageObj}
							imgStyles={mainImageStyles}
						/>

						<div className="credit-main">
							<span>{images[this.state.currentIndex].imageObj.credit}</span>
						</div>
					</div>

					<div
						className="img-secondary"
						style={{
							...secondaryImageStyles,
							visibility: showNextImage ? 'visible' : 'hidden',
							backgroundImage: showNextImage
								? `url('${imageUrl(
										images[(this.state.currentIndex + 1) % images.length].imageObj.imageSrc,
								  )}')`
								: 'none',
						}}
						onClick={this.handleNextClick}
					/>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(CarouselBlurEffectVertical);
