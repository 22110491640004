import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';

import {styled} from '@mui/material/styles';

import './styles.scss';

const Root = styled('div')(({theme}) => ({
	'z-index': 5,
	'padding': 0,
	'position': 'relative',
	'& .two-image-composition-with-text': {
		'display': 'flex',
		'flexDirection': 'row',
		'alignItems': 'center',
		'justifyContent': 'center',
		'flexWrap': 'wrap',
		'&__firstBlock': {
			'width': '70%',
			'&__image': {
				position: 'relative',
				paddingBottom: '50%',
			},
		},
		'&__secondBlock': {
			'width': '30%',
			'paddingLeft': '4%',
			'paddingRight': '4%',
			'&__element': {
				'display': 'flex',
				'flexDirection': 'column',
				'&__image': {
					width: '100%',
					paddingBottom: '65%',
					position: 'relative',
				},
				'&__text': {
					'paddingTop': '5%',
					'&__title': {
						'& .ql-editor': {
							fontWeight: 600,
							fontSize: '24px',
							lingHeight: '29px',
						},
					},
					'&__description': {
						'& .ql-editor': {
							fontWeight: 400,
							fontSize: '17px',
							lingHeight: 'px',
						},
					},
				},
			},
		},
		'&__image-caption': {
			background: 'none',
			padding: '5px',
			textAlign: 'left',
		},
		'&__caption-credit': {
			color: 'var(--themeCreditsColor, #b7b7b7)',
			fontSize: '11px',
			fontWeight: '500',
		},
	},
	[theme.breakpoints.down('lg')]: {
		'& .two-image-composition-with-text': {
			'&__firstBlock': {
				'width': '50%',
				'&__image': {
					paddingBottom: '120%',
				},
			},
			'&__secondBlock': {
				width: '50%',
			},
		},
	},
	[theme.breakpoints.down('md')]: {
		'& .two-image-composition-with-text': {
			'flexDirection': 'column',
			'&__firstBlock': {
				'width': '100%',
				'&__image': {
					position: 'relative',
					paddingBottom: '50%',
				},
			},
			'&__secondBlock': {
				'width': '100%',
				'paddingTop': '4%',
				'&__element': {
					'flexDirection': 'row',
					'gap': '4%',
					'alignItems': 'center',
					'justifyContent': 'center',
					'&__image': {
						width: '35vw',
						paddingBottom: '40vw',
					},
					'&__text': {
						width: '50vw',
						// paddingLeft: '4%',
						// paddingRight: '4%',
						paddingTop: 0,
					},
				},
			},
		},
	},
	[theme.breakpoints.down('sm')]: {
		'& .two-image-composition-with-text': {
			'&__secondBlock': {
				'width': '100%',
				'paddingTop': '0',
				'marginTop': '-10vw',
				'&__element': {
					'flexDirection': 'column',
					'gap': '5vw',
					'alignItems': 'center',
					'justifyContent': 'center',
					'&__image': {
						width: '60vw',
						paddingBottom: '40vw',
					},
					'&__imageWrapper': {
						padding: '4px',
						backgroundColor: '#fff',
						position: 'relative',
					},
					'&__text': {
						'width': '70vw',
						// paddingLeft: '4%',
						// paddingRight: '4%',
						'paddingTop': 0,
						'& .ql-editor': {
							textAlign: 'center',
						},
					},
				},
			},
		},
	},
}));

class TwoImageCompositionWithText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de dos imágenes con un texto`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		title: 'DUS QUE NOBIST VELESEQUE VELLORE',
		text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',

		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
					footer: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
				},
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const title = data.title || '';
		const text = data.text || '';
		const images = data.images || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'TwoImageCompositionWithText']);

		const mainClass = 'two-image-composition-with-text';
		const classes = classNames({
			// brick: true,
			[mainClass]: true,
		});

		return (
			<Container
				size="fullWidth"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Root>
					<div className={classes}>
						<div className={`${mainClass}__firstBlock`}>
							<Animation
								playAnimation={this.state.playEffect}
								delay={1}
								effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
							>
								<div className={`${mainClass}__firstBlock__image`}>
									<NewImageClickable imgObj={images[0].imageObj} clickable={false} />
								</div>
								{images[0].imageObj.credit && (
									<div className={`${mainClass}__image-caption`}>
										<div className={`${mainClass}__caption-credit`}>
											{images[0].imageObj.credit}
										</div>
									</div>
								)}
							</Animation>
						</div>
						<div className={`${mainClass}__secondBlock`}>
							<div className={`${mainClass}__secondBlock__element`}>
								<div className={`${mainClass}__secondBlock__element__imageContainer`}>
									<Animation
										playAnimation={this.state.playEffect}
										delay={1.5}
										effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
									>
										<div className={`${mainClass}__secondBlock__element__imageWrapper`}>
											<div className={`${mainClass}__secondBlock__element__image`}>
												<NewImageClickable imgObj={images[1].imageObj} clickable={true} />
											</div>
										</div>

										{images[1].imageObj.credit && (
											<div className={`${mainClass}__image-caption`}>
												<div className={`${mainClass}__caption-credit`}>
													{' '}
													{images[1].imageObj.credit}{' '}
												</div>
											</div>
										)}
									</Animation>
								</div>
								<div className={`${mainClass}__secondBlock__element__text`}>
									<Animation
										playAnimation={this.state.playEffect}
										delay={2}
										effect={Array.isArray(brickAnimation) ? brickAnimation[2] : brickAnimation}
									>
										<div className={`${mainClass}__secondBlock__element__text__title`}>
											<FrontEditableText fieldName="title" text={title} set={this.props.set} />
										</div>
										<div className={`${mainClass}__secondBlock__element__text__description`}>
											<FrontEditableText fieldName="text" text={text} set={this.props.set} />
										</div>
									</Animation>
								</div>
							</div>
						</div>
					</div>
				</Root>
			</Container>
		);
	}
}

export default brickWrapper(TwoImageCompositionWithText);
